import { DocumentTypeModel } from '@hindsight/database';

export function formatToDollars(
  priceInCents: DocumentTypeModel['priceInCents'],
) {
  if (!priceInCents) {
    return 0;
  }
  const priceInDollars = priceInCents / 100;
  return priceInDollars;
}

export function formatToCents(
  priceInDollars: DocumentTypeModel['priceInCents'],
) {
  if (!priceInDollars) {
    return 0;
  }

  const priceInCents = priceInDollars * 100;
  return priceInCents;
}

export function formatBeforeCreationWarnMessage(inputText: string) {
  if (!inputText) {
    return '';
  }

  return inputText;
}
