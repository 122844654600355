import { makeStyles } from '@material-ui/core';

export const showStyles = makeStyles(theme => ({
  gridContainer: {
    margin: '1em',
  },
  noCard: {
    marginTop: `${theme.spacing(8)}px`,
    textAlign: 'center',
  },
}));

export default showStyles;
