import { Drawer, makeStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import { List, Datagrid, TextField, ListProps, DateField } from 'react-admin';
import { Route } from 'react-router';
import { useHistory } from 'react-router-dom';

import ListPagination from 'components/ListPagination';
import RatingStatusField from 'components/RatingStatusField';
import StarRatingList from 'components/StarRatingField';

import AcceptRatingsButton from './AcceptRatingsButton';
import RejectRatingsButton from './RejectRatingsButton';
import RatingFilters from '../RatingFilters';
import RatingEdit from '../RatingEdit';

export const useStyles = makeStyles({
  drawerContent: {
    width: 400,
  },
});

const PostBulkActionButtons = (props: JSX.IntrinsicAttributes) => (
  <Fragment>
    <AcceptRatingsButton {...props} />
    <RejectRatingsButton {...props} />
  </Fragment>
);

export const RatingsList: React.FC<ListProps> = props => {
  const classes = useStyles();
  const history = useHistory();

  const handleClose = () => {
    history.push('/rating');
  };

  return (
    <React.Fragment>
      <List
        {...props}
        bulkActionButtons={<PostBulkActionButtons />}
        filters={<RatingFilters />}
        exporter={false}
        pagination={<ListPagination />}
        perPage={50}>
        <Datagrid stickyHeader rowClick="edit">
          <DateField
            locales="en-US"
            source="createdAt"
            label="Date"
            textAlign="center"
            sortBy="customerrating.createdAt"
          />
          <TextField
            source="contractor.user.name"
            label="Contractor"
            textAlign="center"
            sortBy="contractor:user.name"
          />
          <TextField
            source="customer.name"
            label="Customer"
            textAlign="center"
          />
          <StarRatingList source="value" label="Rating" textAlign="center" />
          <TextField
            source="overallDescription"
            label="Comments"
            textAlign="center"
          />
          <DateField
            label="Customer answered at"
            source="customerAnsweredAt"
            locales="en-US"
            textAlign="center"
            emptyText="N/A"
          />
          <RatingStatusField
            source="status"
            label="Status"
            textAlign="center"
            sortBy="customerrating.status"
          />
        </Datagrid>
      </List>
      <Route path="/rating/:id">
        {({ match }) => {
          const id = match?.params?.id;
          const isOpened = id != null;
          // Hack around screen error when closing aside component it stills make request with id undefined
          if (id === undefined) {
            return null;
          }

          return (
            <Drawer open={isOpened} anchor="right" onClose={handleClose}>
              <RatingEdit
                className={classes.drawerContent}
                id={id!}
                onCancel={handleClose}
                {...props}
              />
            </Drawer>
          );
        }}
      </Route>
    </React.Fragment>
  );
};
