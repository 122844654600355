import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title, fetchStart, fetchEnd, useNotify } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from 'react-redux';
import httpClient from '../../providers/CustomHttpClient';
import { apiUrl } from 'providers/data';
import DashboardComponent from 'components/Dashboard';
import { AdminDashboard } from '@hindsight/database';
import { showStyles } from './styles';

const DashboardShow: React.FC = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState<AdminDashboard>();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const classes = showStyles();

  useEffect(() => {
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator
    httpClient(`${apiUrl}/dashboard`, { method: 'GET' })
      .then(({ json }) => {
        setData(json);
      })
      .catch(() => {
        notify('Error: dashboard not loaded', 'warning');
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
      });
  }, []);

  if (loading)
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );

  return (
    <Card>
      <Title title="Dashboard" />
      <CardContent>
        <DashboardComponent data={data} />
      </CardContent>
    </Card>
  );
};

export default DashboardShow;
