import * as React from 'react';
import { useListContext, EditButton } from 'react-admin';
import { Card, CardActions, CardContent, CardHeader } from '@material-ui/core';
import showStyles from './styles';
import DocumentTitle from './DocumentTitle';
import DocumentPrice from './DocumentPrice';

const DocumentGrid = () => {
  const { ids, data, basePath } = useListContext();
  const classes = showStyles();

  return (
    <div className={classes.cardContainer}>
      {ids.map(id => (
        <Card key={id} className={classes.cardStyle}>
          <CardHeader
            title={<DocumentTitle record={data[id]} source="name" />}
          />
          <CardContent>
            <DocumentPrice record={data[id]} source="priceInCents" />
          </CardContent>
          <CardActions>
            <EditButton
              resource="document"
              className={classes.editButton}
              basePath={basePath}
              record={data[id]}
              label="Edit info"
            />
          </CardActions>
        </Card>
      ))}
    </div>
  );
};

export default DocumentGrid;
