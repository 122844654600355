import { format, parse } from 'date-fns';

/**
 * Converts a date to a timezoned ISOString
 * Example: 2021-05-15 becomes 2021-05-15T03:00:00 if user is on a -3 time zone
 * This compensates requests to server, which is on UTC
 */
export const zonedDateISOString = (dateString: string): string => {
  const today = new Date();
  const timezonedDate = new Date(
    today.valueOf() + today.getTimezoneOffset() * 60 * 1000,
  );
  const x = parse(dateString, 'yyyy-MM-dd', timezonedDate);
  return x.toISOString();
};

/**
 * Converts a string to formatted date string on Localized date format
 */
export const formatDate = (date: string) => format(new Date(date), 'P');
