import { makeStyles } from '@material-ui/core';

export const showStyles = makeStyles(theme => ({
  cardStyle: {
    width: 300,
    minHeight: 200,
    margin: '0.5em',
    display: 'inline-block',
    verticalAlign: 'top',
    textAlign: 'center',
    borderRadius: '20px',
  },
  editButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: `${theme.spacing(2.5)}px`,
  },
}));

export default showStyles;
