import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  EditProps,
  useNotify,
  useRedirect,
  useRefresh,
  Toolbar,
  SaveButton,
  FunctionField,
  Labeled,
  SelectInput,
  useGetOne,
} from 'react-admin';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import axios from 'axios';
import showStyles from './styles';
import { AddressModel, CustomerModel } from '@hindsight/database';
import { googlePlacesEntryUrl, googlePlacesKey } from 'providers/data';
import { Switch } from '@material-ui/core';
import { formatStatus } from '../ShowTabs/utils';

type Customer = RequireField<CustomerModel, 'id'>;

const CustomerEdit: React.FC<EditProps> = props => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const classes = showStyles();
  const { data } = useGetOne('customer', props.id!);

  const [status, setStatus] = React.useState<'enabled' | 'disabled'>();
  const [addressSelected, setAddressSelected] = React.useState<AddressModel>();
  const [autocompleteValue, setAutocompleteValue] = React.useState<{
    label: string;
    value: {
      description: string;
      place_id: string;
    };
  }>();

  const isDisabled = status === 'disabled' ? true : false;

  const getAddressData = (
    previousData: AddressModel,
    key:
      | 'lat'
      | 'lng'
      | 'googlePlaceId'
      | 'streetNumber'
      | 'streetName'
      | 'city'
      | 'province'
      | 'county'
      | 'postalCode',
  ) => {
    if (addressSelected && !!addressSelected[key]) {
      return addressSelected[key];
    }
    if (previousData && !!previousData[key]) {
      return previousData[key];
    }
    return undefined;
  };

  const CustomerEditToolbar = (props: JSX.IntrinsicAttributes) => (
    <Toolbar {...props}>
      <SaveButton
        transform={data => ({
          type: data.type ?? undefined,
          email: data.email ?? undefined,
          phoneNumber: data.phoneNumber ?? undefined,
          name: data.name ?? undefined,
          status: status ? status : data.status,
          address: {
            lat: getAddressData(data.address, 'lat'),
            lng: getAddressData(data.address, 'lng'),
            googlePlaceId: getAddressData(data.address, 'googlePlaceId'),
            streetNumber: getAddressData(data.address, 'streetNumber'),
            streetName: getAddressData(data.address, 'streetName'),
            city: getAddressData(data.address, 'city'),
            province: getAddressData(data.address, 'province'),
            county: getAddressData(data.address, 'county'),
            postalCode: getAddressData(data.address, 'postalCode'),
          },
        })}
      />
    </Toolbar>
  );

  const handleChangeStatus = () => {
    const newStatus = isDisabled ? 'enabled' : 'disabled';
    setStatus(newStatus);
  };

  const handleChangeAddress = async (selection: {
    label: string;
    value: {
      description: string;
      place_id: string;
    };
  }) => {
    if (selection.value.place_id) {
      setAutocompleteValue({
        label: selection.value.description,
        value: {
          description: selection.value.description,
          place_id: selection.value.place_id,
        },
      });
      const result = await axios.post(
        `${googlePlacesEntryUrl}details/json?key=${googlePlacesKey}&place_id=${selection.value.place_id}`,
      );
      const {
        data: {
          result: {
            geometry: { location },
            address_components,
          },
        },
      } = result;
      const { lat, lng } = location;

      const searchAddressComponent = (component: string) =>
        address_components?.find(({ types }: { types: Array<string> }) =>
          types.find(value => value === component),
        )?.long_name;

      setAddressSelected({
        lat: lat,
        lng: lng,
        googlePlaceId: selection.value.place_id,
        streetNumber: searchAddressComponent('street_number'),
        streetName: searchAddressComponent('route'),
        city: searchAddressComponent('administrative_area_level_2'),
        province: searchAddressComponent('administrative_area_level_1'),
        county: searchAddressComponent('locality'),
        postalCode: searchAddressComponent('postal_code'),
      });
    }
  };

  const onSuccess = async () => {
    notify(`customer saved`);
    redirect(`/customer/${props.id}/show`);
    refresh();
  };

  const onFailure = (error: Error) => {
    notify(error.toString(), 'warning');
  };

  React.useEffect(() => {
    if (data?.address) {
      setAutocompleteValue({
        label: data?.address?.formattedFull,
        value: {
          description: data?.address?.formattedFull,
          place_id: data?.address?.googlePlaceId,
        },
      });
    }

    setStatus(data?.status);
  }, [data]);

  return (
    <Edit
      onFailure={onFailure}
      onSuccess={onSuccess}
      mutationMode="pessimistic"
      {...props}>
      <SimpleForm toolbar={<CustomerEditToolbar />}>
        <div className={classes.container}>
          <FunctionField<Customer>
            render={record => {
              return (
                <SelectInput
                  source="type"
                  fullWidth={true}
                  choices={[
                    { id: 'residential', text: 'residential' },
                    { id: 'business', text: 'business' },
                  ]}
                  optionValue="id"
                  optionText="text"
                  initialValue={record?.type}
                />
              );
            }}
          />
          <TextInput label="name" source="name" />
          <TextInput label="Phone n°" source="phoneNumber" />
          <Labeled label="Address">
            <GooglePlacesAutocomplete
              selectProps={{
                value: autocompleteValue,
                styles: {
                  control: (provided: Record<string, unknown>) => ({
                    ...provided,
                    background: '#E2E2E2',
                    height: 50,
                  }),
                  menu: (provided: Record<string, unknown>) => ({
                    ...provided,
                    zIndex: 2,
                  }),
                },
                onChange: handleChangeAddress,
              }}
              apiKey={googlePlacesKey}
            />
          </Labeled>
          <TextInput label="E-mail" source="email" />
          <div className={classes.statusContainer}>
            <Labeled label="Status">
              <span className={classes.cardItem}>{formatStatus(status)}</span>
            </Labeled>
            <Switch
              checked={!isDisabled}
              onChange={handleChangeStatus}
              name="customerStatus"
            />
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};

export default CustomerEdit;
