import React from 'react';
import { IconButton, Card } from '@material-ui/core';
import { showStyles } from './styles';
import { ReactComponent as CancelledIcon } from '../../../assets/icon-cancelled.svg';

type CancelledUsersProps = {
  cancelledUsers: number | undefined;
};

const CancelledUsers: React.FC<CancelledUsersProps> = ({ cancelledUsers }) => {
  const classes = showStyles();

  return (
    <Card className={classes.cardContainer}>
      <div className={classes.cardHeader}>
        <IconButton disabled>
          <CancelledIcon />
        </IconButton>
      </div>
      <div className={classes.cardContent}>
        <div className={classes.totalFont}>{cancelledUsers?.toFixed(2)}%</div>
        <div className={classes.textFont}>Cancelled Users</div>
      </div>
    </Card>
  );
};

export default CancelledUsers;
