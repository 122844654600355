import { makeStyles } from '@material-ui/core';

export const showStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundSize: 'cover',
    justifyContent: 'flex-start',
    textAlign: 'center',
    marginTop: `${theme.spacing(3)}px`,
  },
  textFont: {
    fontSize: `${theme.spacing(2)}px`,
  },
  icon: {
    height: 80,
    width: 80,
  },
}));

export default showStyles;
