import { DocumentTypeModel } from '@hindsight/database';

export function formatPrice(priceInCents: DocumentTypeModel['priceInCents']) {
  if (!priceInCents) {
    return 'Free';
  }
  const priceInDollars = priceInCents / 100;
  return priceInDollars.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
}
