import {
  DataProvider as DataProviderType,
  GetListParams,
  GetListResult,
  GetOneParams,
  HttpError,
  Identifier,
} from 'react-admin';
import { stringify } from 'query-string';
import httpClient from '../CustomHttpClient';
import { zonedDateISOString } from 'utils';
import { addDays } from 'date-fns';
import { DocumentTypeModel } from '@hindsight/database';

export interface ApiGetOneParams extends GetOneParams {
  documentTypeId?: Identifier;
}

export const apiUrl = process.env.REACT_APP_API_ENTRYPOINT as string;
export const webUrl = process.env.REACT_APP_HINDSIGHT_WEB_URI as string;
export const googlePlacesKey = process.env
  .REACT_APP_GOOGLE_PLACES_KEY as string;
export const googlePlacesEntryUrl = process.env
  .REACT_APP_GOOGLE_PLACES_ENTRY_URL as string;

const resolveCreatedAtFilterKeyValue = (
  key: string,
  value: string,
): { key: string; value: string } => {
  // "Greated than or equal" and "Less than" will basically match for ISOString date on client's timezone
  if (key.endsWith('createdAt_ge') || key.endsWith('createdAt_lt')) {
    return {
      key,
      value: zonedDateISOString(value),
    };
  }

  // "Greater than" and "Less than or equal" will sum 1 day and match for ISOString date on client's timezone
  // This sum is important becaus:
  // On "Greater than" we want the date to be ignored
  // On "Less than or equal" we want the date to be included (including every hours on that date)
  if (key.endsWith('createdAt_gt') || key.endsWith('createdAt_le')) {
    const zonedDate = zonedDateISOString(value);
    const zonedDatePlusOne = addDays(new Date(zonedDate), 1);
    return {
      key,
      value: zonedDatePlusOne.toISOString(),
    };
  }

  // By default, use exact date match
  return {
    key,
    value,
  };
};

/**
 * This resolver will map filter values from react-admin resources to our API's query params format.
 */
const resolveFilterKeyValue = (
  key: string,
  value: string | number,
): { key: string; value: string | number } => {
  if (typeof value === 'number') {
    return { key, value };
  }

  if (key.includes('createdAt')) {
    return resolveCreatedAtFilterKeyValue(key, value);
  }

  // By default, return the value string with `%` (LIKE) operators itself
  return {
    key: key,
    value: `%${value}%`,
  };
};

const resolveApiQuery = (params: GetListParams): string => {
  const filter: Record<string, string | number> = {};
  Object.entries(params.filter || {}).forEach(([rawKey, value]) => {
    // Hack around dot issue for filters: https://github.com/marmelab/react-admin/issues/2102
    const key = rawKey.replace('->', '.');

    const { key: filterKey, value: filterValue } = resolveFilterKeyValue(
      key,
      value as string,
    );

    filter[filterKey] = filterValue;
  });

  const { page, perPage } = params.pagination;
  const { field: orderBy, order } = params.sort;
  // Hack around dot issue for filters: https://github.com/marmelab/react-admin/issues/2102
  const formattedOrderBy = orderBy.replace('->', '.');

  const query = {
    limit: perPage,
    page: page - 1,
    order: order,
    orderBy: formattedOrderBy,
    ...filter,
  };

  return stringify(query);
};

const resolveApiPathByResource = (resource: string) => {
  switch (resource) {
    case 'rating':
      return 'customer/rating';
    case 'document':
      return 'legal/document-type';
    case 'collection':
      return 'legal/collection';
    default:
      return resource;
  }
};

const resolveApiGetOnePathByResource = (
  resource: string,
  id: Identifier,
  documentTypeId?: Identifier,
) => {
  switch (resource) {
    case 'rating':
      return `customer/rating/${id}`;
    case 'contractorIdentificationPicture':
      return `contractor/${id}/identification-picture`;
    case 'collection':
      return `legal/collection/${id}`;
    case 'collectionDocumentFile':
      return `legal/collection/${id}/document/${documentTypeId}/file`;
    case 'document':
      return 'legal/document-type';
    default:
      return `${resource}/${id}`;
  }
};

const resolveApiUpdatePathByResource = (resource: string, id: Identifier) => {
  switch (resource) {
    case 'rating':
      return `customer/rating/${id}`;
    case 'userStatus':
      return `user/${id}/status`;
    case 'document':
      return `legal/document-type/${id}`;
    default:
      return `${resource}/${id}`;
  }
};

const resolveApiCreateByResource = (resource: string) => {
  switch (resource) {
    case 'contractor':
      return `/contractor/incomplete-signup`;
    default:
      return `${resource}`;
  }
};

const resolveApiUpdateManyPathByResource = (resource: string) => {
  switch (resource) {
    case 'rating':
      return 'customer/rating/status';
    default:
      return resource;
  }
};

export const DataProvider = {
  getList: async (resource, params) => {
    const query = resolveApiQuery(params);
    const path = resolveApiPathByResource(resource);

    const uri = `${apiUrl}/${path}?${query}`;
    return httpClient(uri).then(
      ({ json }) =>
        ({
          data: json.results,
          total: json.total || null, // document list has no pagination - to skip pagination in getList, react admin requires to insert null in total
        } as GetListResult),
    );
  },

  getOne: async (resource, params: ApiGetOneParams) => {
    const path = resolveApiGetOnePathByResource(
      resource,
      params.id,
      params.documentTypeId,
    );

    const uri = `${apiUrl}/${path}`;

    return httpClient(uri).then(({ json }) => {
      switch (resource) {
        // Get document type doesn't have an endpoint to get by id
        // So we need to filter the selected document from all documents
        case 'document':
          const selectedDoc = json.results.find(
            (doc: DocumentTypeModel) => doc.id == params.id,
          );

          if (selectedDoc.length === 0) {
            return new HttpError('Not found', 404);
          }

          return {
            data: {
              ...selectedDoc,
              id: selectedDoc.id,
            },
          };
        default:
          return {
            data: {
              ...json,
              // If JSON doesn't have an ID, we use the params.id to ensure react-admin will store it properly
              // Important for the `contractorIdentificationPicture` resource
              id: json.id || params.id,
            },
          };
      }
    });
  },

  update: (resource, params) => {
    const path = resolveApiUpdatePathByResource(resource, params.id);
    const url = `${apiUrl}/${path}`;

    return httpClient(url, {
      method: 'PATCH',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: {
        ...json,
        // If JSON doesn't have an ID, we use the params.id to ensure react-admin will store it properly
        // Important for the `userStatus` resource
        id: json.id || params.id,
      },
    }));
  },

  create: (resource, params) => {
    const path = resolveApiCreateByResource(resource);
    const url = `${apiUrl}/${path}`;

    return httpClient(url, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      return {
        data: {
          ...json,
          id: json.id,
        },
      };
    });
  },

  updateMany: (resource, { ids, data }) => {
    const path = resolveApiUpdateManyPathByResource(resource);
    const url = `${apiUrl}/${path}`;

    const dataToSend = {
      customerRatings: ids.map(id => ({
        id: id,
        ...data,
      })),
    };

    return httpClient(url, {
      method: 'PATCH',
      body: JSON.stringify(dataToSend),
    }).then(({ json }) => ({ data: [json] }));
  },
} as DataProviderType;
