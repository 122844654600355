import { UserModel } from '@hindsight/database';

export function formatStatus(status: UserModel['status']) {
  switch (status) {
    case 'enabled':
      return 'Active';
    case 'disabled':
      return 'Inactive';
    default:
      return '';
  }
}
