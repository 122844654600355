import React from 'react';
import { IconButton, Card } from '@material-ui/core';
import { showStyles } from './styles';
import { ReactComponent as ChartIcon } from '../../../assets/icon-chart.svg';

type TotalUsersProps = {
  totalUsers: number | undefined;
};

const TotalUsers: React.FC<TotalUsersProps> = ({ totalUsers }) => {
  const classes = showStyles();

  return (
    <Card className={classes.cardContainer}>
      <div className={classes.cardHeader}>
        <IconButton disabled>
          <ChartIcon />
        </IconButton>
      </div>
      <div className={classes.cardContent}>
        <div className={classes.totalFont}>{totalUsers}</div>
        <div className={classes.textFont}>
          Users that have completed the sign up proccess (Contractors)
        </div>
      </div>
    </Card>
  );
};

export default TotalUsers;
