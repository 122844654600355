import * as React from 'react';
import { FieldProps } from 'react-admin';
import { CustomerRatingModel } from '@hindsight/database';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  root: {
    opacity: 0.87,
    whiteSpace: 'nowrap',
    display: 'flex',
    textAlign: 'center',
  },
});
type CustomerRating = RequireField<CustomerRatingModel, 'id'>;

//eslint-disable-next-line
const RatingAnswerField: React.FC<FieldProps<CustomerRating & any>> = ({
  record,
  source,
}) => {
  const classes = useStyles();
  const answer = record[`${source}`] == 1 ? 'Yes' : 'No';

  return (
    <div className={classes.container}>
      <span className={classes.root}>{answer}</span>
    </div>
  );
};

RatingAnswerField.defaultProps = {
  addLabel: true,
};

export default RatingAnswerField;
