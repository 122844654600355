import * as React from 'react';
import { FieldProps } from 'react-admin';
import { ContractorModel } from '@hindsight/database';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from 'styles/theme';
type Contractor = RequireField<ContractorModel, 'id'>;

const useStyles = makeStyles({
  inactive: {
    color: theme.palette.error.main,
  },
});

const ContractorStatusField: React.FC<FieldProps<Contractor>> = ({
  record,
}) => {
  const classes = useStyles();

  let status;
  switch (record?.user?.status) {
    case 'enabled':
      status = 'Active';
      break;
    case 'disabled':
      status = 'Inactive';
      break;
    default:
      status = '';
      break;
  }
  return (
    <>
      <span
        className={record?.user?.status == 'disabled' ? classes.inactive : ''}>
        {status}
      </span>
    </>
  );
};

export default ContractorStatusField;
