import classnames from 'classnames';
import lodashGet from 'lodash/get';
import { ReduxState } from 'ra-core';
import * as React from 'react';
import { MenuProps, MenuItemLink } from 'react-admin';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import BuildIcon from '@material-ui/icons/Build';

import { ReactComponent as ContractorIcon } from '../../assets/contractor-icon.svg';
import { ReactComponent as CustomerIcon } from '../../assets/customer-icon.svg';
import { ReactComponent as RatingIcon } from '../../assets/rating-icon.svg';
import { ReactComponent as DocumentIcon } from '../../assets/document-icon.svg';
import { ReactComponent as CollectionIcon } from '../../assets/collection-icon.svg';
import { ReactComponent as DashboardIcon } from '../../assets/dashboard-icon.svg';

export const MENU_WIDTH = 240;
export const CLOSED_MENU_WIDTH = 55;

const useStyles = makeStyles(
  theme => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: '0.5em',
      marginBottom: '1em',
      [theme.breakpoints.only('xs')]: {
        marginTop: 0,
      },
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    open: {
      width: lodashGet(theme, 'menu.width', MENU_WIDTH),
    },
    closed: {
      width: lodashGet(theme, 'menu.closedWidth', CLOSED_MENU_WIDTH),
    },
  }),
  { name: 'RaMenu' },
);

const Menu = (props: MenuProps) => {
  const { className, ...rest } = props;

  const classes = useStyles(props);
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);

  return (
    <div
      className={classnames(
        classes.main,
        {
          [classes.open]: open,
          [classes.closed]: !open,
        },
        className,
      )}
      {...rest}>
      <MenuItemLink
        to="/"
        primaryText="Dashboard"
        leftIcon={<DashboardIcon />}
      />
      <MenuItemLink
        to="/contractor"
        primaryText="Contractors"
        leftIcon={<ContractorIcon />}
      />
      <MenuItemLink
        to="/customer"
        primaryText="Customers"
        leftIcon={<CustomerIcon />}
      />
      <MenuItemLink
        to="/document"
        primaryText="Document Types"
        leftIcon={<DocumentIcon />}
      />
      <MenuItemLink
        to="/collection"
        primaryText="Paperwork"
        leftIcon={<CollectionIcon />}
      />
      <MenuItemLink
        to="/rating"
        primaryText="Ratings"
        leftIcon={<RatingIcon />}
      />
      <MenuItemLink
        to="/configuration"
        primaryText="Settings"
        leftIcon={<BuildIcon style={{ color: '#000000' }} />}
      />
    </div>
  );
};

export default Menu;
