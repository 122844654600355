import { ServiceArea } from '@hindsight/database';
import { CircularProgress } from '@material-ui/core';
import httpClient from 'providers/CustomHttpClient';
import { apiUrl } from 'providers/data';
import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  CreateProps,
  useNotify,
  SelectArrayInput,
  Toolbar,
  SaveButton,
  useRefresh,
  useRedirect,
} from 'react-admin';
import { showStyles } from './styles';

const CreateContractor: React.FC<CreateProps> = props => {
  const [serviceArea, setServiceArea] = React.useState<ServiceArea[]>();
  const [loading, setLoading] = React.useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const classes = showStyles();

  React.useEffect(() => {
    setLoading(true);
    httpClient(`${apiUrl}/service-area`, { method: 'GET' })
      .then(({ json }) => {
        setServiceArea(json.results);
      })
      .catch(() => {
        notify('Error: dashboard not loaded', 'warning');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const choices = serviceArea?.map(service => {
    return { id: service.id, text: service.name };
  });

  const CustomerEditToolbar = (props: JSX.IntrinsicAttributes) => (
    <Toolbar {...props}>
      <SaveButton
        transform={data => {
          const s = {
            user: {
              email: data.email,
              name: data.name,
            },
            serviceAreasIds: data.serviceAreas.map((id: number) => id),
          };
          console.log(s);
          return s;
        }}
      />
    </Toolbar>
  );

  const onSuccess = async () => {
    notify(`contractor saved`);
    redirect(`/contractor`);
    refresh();
  };

  const onFailure = (error: Error) => {
    notify(error.toString(), 'warning');
  };

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Create
      resource="contractor"
      onFailure={onFailure}
      onSuccess={onSuccess}
      {...props}>
      <SimpleForm toolbar={<CustomerEditToolbar />}>
        <TextInput
          source="name"
          label="Name"
          validate={[required()]}
          fullWidth
        />
        <TextInput
          source="email"
          label="E-mail"
          validate={[required()]}
          fullWidth
        />
        <SelectArrayInput
          source="serviceAreas"
          fullWidth={true}
          choices={choices}
          optionValue="id"
          optionText="text"
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
};

export default CreateContractor;
