import { makeStyles } from '@material-ui/core';

export const showStyles = makeStyles(theme => ({
  cardContainer: { paddingLeft: `${theme.spacing(1)}px` },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  fieldItem: {},
  businessContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: `${theme.spacing(1)}px`,
  },
  personalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: `${theme.spacing(1)}px`,
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
  },
  personalTitle: {
    marginTop: `${theme.spacing(1)}px`,
  },
  statusContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  accountOwnerContainer: {
    width: 300,
  },
  saveAccountOwnerButton: {
    marginTop: 6,
    marginBottom: 6,
    float: 'right',
    color: `${theme.palette.primary.light}`,
    textDecoration: 'underline',
    fontSize: 13,
    cursor: 'pointer',
  },
}));

export default showStyles;
