import { makeStyles } from '@material-ui/core';

export const showStyles = makeStyles({
  cardContainer: { paddingLeft: '10px' },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardItem: {
    marginRight: '10px',
  },
  statusContainer: {
    alignItems: 'center',
    display: 'flex',
  },
});

export default showStyles;
