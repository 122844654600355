import React, { useEffect, useState } from 'react';
import { Notification } from 'react-admin';
import { Formik, Form, FormikHelpers } from 'formik';
import { TextField, Button, CircularProgress } from '@material-ui/core';

import { validationSchema, FormValues } from './utils';
import showStyles from './styles';
import httpClient from 'providers/CustomHttpClient';
import { apiUrl } from 'providers/data';

const ChangeSettings: React.FC = () => {
  const [ratingFeedPeriodInHours, setRatingFeedPeriodInHours] = useState(0);
  const [loading, setLoading] = useState(true);

  const classes = showStyles();

  useEffect(() => {
    httpClient(`${apiUrl}/system-settings`, { method: 'GET' })
      .then(({ json }) => {
        const {
          results: { customerRatingFeedPeriodInHours },
        } = json;
        setRatingFeedPeriodInHours(customerRatingFeedPeriodInHours);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    httpClient(`${apiUrl}/system-settings`, {
      method: 'PUT',
      body: JSON.stringify({
        customerRatingFeedPeriodInHours: values.storyTime,
      }),
    })
      .then(() => {
        setRatingFeedPeriodInHours(values.storyTime);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <div className={classes.container}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Formik
              validationSchema={validationSchema}
              initialValues={{
                storyTime: ratingFeedPeriodInHours,
              }}
              onSubmit={handleSubmit}
              validateOnChange={false}>
              {formik => (
                <Form noValidate={true}>
                  <TextField
                    name="storyTime"
                    type={'number'}
                    label="Story time"
                    margin="normal"
                    variant="outlined"
                    value={formik.values.storyTime}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.storyTime)}
                    helperText={formik.errors.storyTime}
                    fullWidth
                    inputProps={{ 'data-testid': 'storyTime' }}
                  />
                  <div className={classes.footerContainer}>
                    <Button
                      disabled={formik.isSubmitting}
                      type="submit"
                      size="large"
                      variant="outlined"
                      color="primary">
                      Save
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
            <Notification />
          </>
        )}
      </div>
    </>
  );
};

export default ChangeSettings;
