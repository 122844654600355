import * as React from 'react';
import { Show, ShowProps, TabbedShowLayout, Tab } from 'react-admin';
import CoworkersTab from './ShowTabs/CoworkersTab';
import CollectionsTab from './ShowTabs/CollectionsTab';
import DetailsTab from './ShowTabs/DetailsTab';

const ContractorsShow: React.FC<ShowProps> = props => {
  return (
    <Show component="div" {...props}>
      <TabbedShowLayout>
        <Tab label="Details">
          <DetailsTab />
        </Tab>

        <Tab label="Coworkers" path="coworkers">
          <CoworkersTab />
        </Tab>

        <Tab label="Collections" path="collections">
          <CollectionsTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ContractorsShow;
