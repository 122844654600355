import React from 'react';
import { SimpleShowLayout, InjectedFieldProps } from 'react-admin';
import { Link } from 'react-router-dom';
import { stringify } from 'querystring';

import showStyles from './styles';

const CollectionsTab: React.FC<InjectedFieldProps> = props => {
  const classes = showStyles();

  return (
    <div className={classes.cardContainer}>
      <SimpleShowLayout {...props} className={classes.cardContent}>
        <Link
          to={{
            pathname: '/collection',
            search: stringify({
              displayedFilters: JSON.stringify({
                'contractor->id': true,
              }),
              filter: JSON.stringify({
                'contractor->id': props.record?.id,
              }),
            }),
          }}>
          View all collections
        </Link>
      </SimpleShowLayout>
    </div>
  );
};

export default CollectionsTab;
