import React from 'react';
import { AdminDashboard } from '@hindsight/database';
import TotalUsers from './TotalUsers';
import ContractorsRating from './ContractorsRating';
import CancelledUsers from './CancelledUsers';
import { showStyles } from './styles';
import CustomersOnDatabase from './CustomersOnDatabase';
import CollectionDocumentsCreated from './CollectionDocumentsCreated';
import MonthlyAverageRating from './MonthlyAverageRating';
import CollectionPaidNotPaid from './CollectionPaidNotPaid';
import MonthlyPlan from './MonthlyPlan';

type DashboardComponentProps = {
  data: AdminDashboard | undefined;
};

const DashboardComponent: React.FC<DashboardComponentProps> = ({ data }) => {
  const classes = showStyles();

  return (
    <>
      <div className={classes.componentHeader}>
        <TotalUsers totalUsers={data?.contractorCount} />
        <ContractorsRating contractorsRating={data?.contractorRatingCount} />
        <CancelledUsers cancelledUsers={data?.contractorDisabledPercentage} />
      </div>
      <div className={classes.componentContainer}>
        <CustomersOnDatabase
          customerBusiness={data?.customerBusinessTypeCount}
          customerResidential={data?.customerResidentialTypeCount}
          customerTotal={data?.customerCount}
        />
        <CollectionDocumentsCreated
          collectionDocumentsTotal={data?.collectionDocumentCount}
        />
      </div>
      <div className={classes.componentContainer}>
        <MonthlyAverageRating
          monthlyAverageRating={data?.monthlyAverageRating}
        />
        <CollectionPaidNotPaid
          collectionPaidNotPaidPercentage={data?.collection}
        />
      </div>
      <div className={classes.componentContainer}>
        <MonthlyPlan monthlyPlanData={data?.monthlyPlan} />
      </div>
    </>
  );
};

export default DashboardComponent;
