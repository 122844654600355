import { makeStyles } from '@material-ui/core';

export const showStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerFields: {
    width: '45%',
  },
  notificationField: {
    width: '10%',
  },
}));

export default showStyles;
