import * as React from 'react';
import { FieldProps } from 'react-admin';
import { Typography } from '@material-ui/core';
import { formatPrice } from './utils';

const DocumentPrice: React.FC<FieldProps> = ({ record }) => {
  return (
    <>
      <Typography variant="h6">{formatPrice(record?.priceInCents)}</Typography>
    </>
  );
};

export default DocumentPrice;
