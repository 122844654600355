import {
  CircularProgress,
  Switch,
  Typography,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DownloadImageField from 'components/DownloadImageField';
import StyledTextField from 'components/StyledTextField';
import React, { useEffect, useState } from 'react';
import {
  SimpleShowLayout,
  InjectedFieldProps,
  Labeled,
  ArrayField,
  SingleFieldList,
  ChipField,
  FunctionField,
  useNotify,
  useUpdate,
  useRefresh,
  Identifier,
  SelectField,
} from 'react-admin';
import showStyles from './styles';
import { ContractorBusiness, ContractorModel } from '@hindsight/database';
import { formatStatus } from './utils';
import httpClient from 'providers/CustomHttpClient';
import { apiUrl } from 'providers/data';
import { formatDate } from 'utils';

type Contractor = RequireField<ContractorModel, 'id'>;

const resolveContractorType = (
  id?: Identifier,
  contractorBusiness?: ContractorBusiness,
) => {
  // If the contractor has no subscription, then the type is unknown
  if (!contractorBusiness?.subscription) return 'N/A';

  // If the contractor has a subscription and the id of the user who registered
  // is the same id of this contractor, then the type is 'Owner'
  if (id === contractorBusiness.subscription.userId) return 'Owner';

  // Otherwise your type is 'Coworker'
  return 'Coworker';
};

const DetailsTab: React.FC<InjectedFieldProps> = props => {
  const classes = showStyles();
  const notify = useNotify();
  const [update] = useUpdate();
  const refresh = useRefresh();

  const [contractorBusinesses, setContractorBusinesses] =
    useState<ContractorBusiness[]>();
  const [contractorBusinessSelected, setContractorBusinessSelected] = useState<{
    id: number;
    name: string;
  } | null>({
    id: props.record?.contractorBusiness.id,
    name: props.record?.contractorBusiness.name ?? 'N/A',
  });

  const [loading, setLoading] = useState(true);

  const isDisabled = props.record?.user.status === 'disabled' ? true : false;

  const isCoworker =
    resolveContractorType(
      props.record?.id,
      props.record?.contractorBusiness,
    ) === 'Coworker';

  const changeStatus = () => {
    const newStatus = isDisabled ? 'enabled' : 'disabled';
    update(
      'userStatus',
      props.record?.id,
      { status: newStatus },
      {},
      {
        onSuccess: () => {
          refresh();
          notify('Contractor updated');
        },
        onFailure: (error: Error) => {
          notify(error.toString(), 'warning');
        },
      },
    );
  };

  const handleChangeOwner = () => {
    setLoading(true);
    if (contractorBusinessSelected?.id) {
      httpClient(`${apiUrl}/contractor/${props.record?.id}`, {
        method: 'PATCH',
        body: JSON.stringify({
          contractorBusinessId: contractorBusinessSelected.id,
        }),
      })
        .then(({ json }) => {
          setContractorBusinesses(json.results);
          refresh();
          setLoading(false);
        })
        .catch(() => {
          notify('Error: Change account owner', 'warning');
          setLoading(false);
        });
    } else {
      notify('Error: Owner is required', 'warning');
      setLoading(false);
    }
  };

  const getAllContractorsbusiness = () => {
    httpClient(`${apiUrl}/contractor/business`, { method: 'GET' })
      .then(({ json }) => {
        setContractorBusinesses(json);
        setLoading(false);
      })
      .catch(() => {
        notify('Error: get contractors business', 'warning');
      });
  };

  useEffect(() => {
    getAllContractorsbusiness();
  }, []);

  useEffect(() => {
    setContractorBusinessSelected({
      id: props.record?.contractorBusiness.id,
      name: props.record?.contractorBusiness.name ?? 'N/A',
    });
  }, [props.record]);

  const choices = contractorBusinesses?.map(business => {
    return {
      id: parseInt(String(business?.id)),
      name: business?.name,
    };
  });

  return (
    <div className={classes.cardContainer}>
      <SimpleShowLayout {...props} className={classes.cardContent}>
        <Typography variant="h5">Business Information</Typography>
        <div className={classes.businessContainer}>
          <div className={classes.fieldsContainer}>
            <Labeled label="Business Name">
              <StyledTextField
                className={classes.fieldItem}
                source="contractorBusiness.name"
              />
            </Labeled>
            <Labeled label="Business Adress">
              <StyledTextField
                className={classes.fieldItem}
                source="contractorBusiness.address.formattedFull"
              />
            </Labeled>
            <Labeled label="Services">
              <ArrayField source="contractorBusiness.serviceAreas">
                <SingleFieldList linkType={false}>
                  <ChipField source="name" />
                </SingleFieldList>
              </ArrayField>
            </Labeled>
            <Labeled label="Additional Information">
              <StyledTextField
                className={classes.fieldItem}
                source="contractorBusiness.additionalInformation"
              />
            </Labeled>
            <Labeled label="Employer Identification Number">
              <StyledTextField
                className={classes.fieldItem}
                source="contractorBusiness.employerIdentificationNumber"
              />
            </Labeled>
            <Labeled label="Documents">
              <DownloadImageField className={classes.fieldItem} source="id" />
            </Labeled>
          </div>
          <div className={classes.fieldsContainer}>
            <Labeled label="Website">
              <StyledTextField
                className={classes.fieldItem}
                source="contractorBusiness.website"
              />
            </Labeled>
            <Labeled label="Facebook Page">
              <StyledTextField
                className={classes.fieldItem}
                source="contractorBusiness.fbPage"
              />
            </Labeled>
            <Labeled label="Google Page">
              <StyledTextField
                className={classes.fieldItem}
                source="contractorBusiness.googlePage"
              />
            </Labeled>
            <Labeled label="Yelp Page">
              <StyledTextField
                className={classes.fieldItem}
                source="contractorBusiness.yelpPage"
              />
            </Labeled>
            <Labeled label="Instagram Page">
              <StyledTextField
                className={classes.fieldItem}
                source="contractorBusiness.instagramPage"
              />
            </Labeled>
            {loading ? (
              <CircularProgress />
            ) : (
              isCoworker && (
                <div className={classes.accountOwnerContainer}>
                  <span
                    className={classes.saveAccountOwnerButton}
                    onClick={handleChangeOwner}>
                    Save
                  </span>
                  <Autocomplete
                    value={contractorBusinessSelected}
                    onChange={(_, newValue) => {
                      if (typeof newValue === 'string') {
                        return;
                      } else if (newValue && newValue.id && newValue.name) {
                        setContractorBusinessSelected({
                          id: newValue?.id,
                          name: newValue?.name,
                        });
                      } else {
                        setContractorBusinessSelected(null);
                      }
                    }}
                    id="change-owner"
                    options={choices ?? []}
                    getOptionLabel={option => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      return `${option.id} | ${option.name}`;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    renderOption={props => (
                      <li {...props} id={`${props.id}`}>
                        {props.id} - {!!props.name ? props.name : 'N/A'}
                      </li>
                    )}
                    style={{ width: 300, marginTop: 6 }}
                    freeSolo
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Account Owner"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              )
            )}
          </div>
        </div>
        <Typography className={classes.personalTitle} variant="h5">
          Personal Information
        </Typography>
        <div className={classes.personalContainer}>
          <div className={classes.fieldsContainer}>
            <Labeled label="Full Name">
              <StyledTextField
                className={classes.fieldItem}
                source="user.name"
              />
            </Labeled>
            <Labeled label="E-mail">
              <StyledTextField
                className={classes.fieldItem}
                source="user.email"
              />
            </Labeled>
            <Labeled label="Address">
              <StyledTextField
                className={classes.fieldItem}
                source="address.formattedFull"
              />
            </Labeled>
          </div>
          <div className={classes.fieldsContainer}>
            <Labeled label="Phone Number">
              <StyledTextField
                className={classes.fieldItem}
                source="user.phoneNumber"
              />
            </Labeled>
            <Labeled label="License Number">
              <StyledTextField
                className={classes.fieldItem}
                source="licenseNumber"
              />
            </Labeled>
            <div className={classes.statusContainer}>
              <Labeled label="Status">
                <FunctionField<Contractor>
                  className={classes.fieldItem}
                  render={record => record && formatStatus(record.user?.status)}
                />
              </Labeled>
              <Switch
                checked={!isDisabled}
                onChange={changeStatus}
                name="customerStatus"
              />
            </div>
          </div>
        </div>
        <Typography className={classes.personalTitle} variant="h5">
          Subscription
        </Typography>
        <div className={classes.personalContainer}>
          <div className={classes.fieldsContainer}>
            <Labeled label="Plan Type">
              <SelectField
                label="Plan"
                source="contractorBusiness.subscription.productSku"
                choices={[
                  {
                    id: 'business_subscription',
                    name: 'Old Business',
                  },
                  {
                    id: 'personal_subscription',
                    name: 'Old Personal',
                  },
                  {
                    id: 'new_business_plan',
                    name: 'Business',
                  },
                  {
                    id: 'new_personal_plan',
                    name: 'Personal',
                  },
                  {
                    id: 'free_admin_subscription',
                    name: 'Free admin',
                  },
                ]}
              />
            </Labeled>
            <Labeled label="Created At">
              <FunctionField<Contractor>
                className={classes.fieldItem}
                render={record =>
                  record &&
                  record.contractorBusiness?.subscription?.createdAt &&
                  formatDate(record.contractorBusiness.subscription.createdAt)
                }
              />
            </Labeled>
          </div>
          <div className={classes.fieldsContainer}>
            <Labeled label="Expiration Date">
              <FunctionField<Contractor>
                className={classes.fieldItem}
                render={record =>
                  record &&
                  record.contractorBusiness?.subscription?.expirationDate &&
                  formatDate(
                    record.contractorBusiness.subscription.expirationDate,
                  )
                }
              />
            </Labeled>
            <Labeled label="Status">
              <StyledTextField
                className={classes.fieldItem}
                source="contractorBusiness.subscription.status"
              />
            </Labeled>
          </div>
        </div>
      </SimpleShowLayout>
    </div>
  );
};

export default DetailsTab;
