import React from 'react';
import { SimpleShowLayout, InjectedFieldProps } from 'react-admin';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';

import showStyles from './styles';

const CoworkersTab: React.FC<InjectedFieldProps> = props => {
  const classes = showStyles();

  return (
    <div className={classes.cardContainer}>
      <SimpleShowLayout {...props} className={classes.cardContent}>
        <Link
          to={{
            pathname: '/contractor',
            search: stringify({
              displayedFilters: JSON.stringify({
                'contractorBusiness->id': true,
              }),
              filter: JSON.stringify({
                'contractorBusiness->id': props.record?.contractorBusiness?.id,
              }),
            }),
          }}>
          View all coworkers
        </Link>
      </SimpleShowLayout>
    </div>
  );
};

export default CoworkersTab;
