import React from 'react';
import { Card, Divider } from '@material-ui/core';
import { CollectionCountType } from '@hindsight/database';
import { BarChart, XAxis, YAxis, CartesianGrid, Tooltip, Bar } from 'recharts';
import { cardStyles } from '../styles';
import { useTheme } from '@material-ui/core/styles';

type CollectionPaidNotPaidProps = {
  collectionPaidNotPaidPercentage: CollectionCountType | undefined;
};

const CollectionPaidNotPaid: React.FC<CollectionPaidNotPaidProps> = ({
  collectionPaidNotPaidPercentage,
}) => {
  const cardClasses = cardStyles();
  const theme = useTheme();

  const arrayData = [
    {
      statusPercentage:
        collectionPaidNotPaidPercentage?.collectionStatusPaidPercentage,
      status: 'Paid',
    },
    {
      statusPercentage:
        collectionPaidNotPaidPercentage?.collectionStatusNotPaidPercentage,
      status: 'Not Paid',
    },
  ];

  return (
    <Card className={cardClasses.cardContainer}>
      <div className={cardClasses.cardHeader}>Collections Paid & Not Paid</div>
      <Divider variant="middle" />
      <div className={cardClasses.cardContent}>
        <BarChart width={400} height={300} data={arrayData}>
          <XAxis dataKey="status" interval={'preserveStartEnd'} />
          <YAxis
            tickFormatter={tick => `${tick}%`}
            ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          />
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={`${theme.palette.text.disabled}`}
          />
          <Tooltip />
          <Bar
            name="Percentage"
            dataKey="statusPercentage"
            fill={`${theme.palette.primary.light}`}
          />
        </BarChart>
      </div>
    </Card>
  );
};

export default CollectionPaidNotPaid;
