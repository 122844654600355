import React from 'react';
import { List, ListProps } from 'react-admin';
import DocumentGrid from 'components/DocumentGrid';

const DocumentList: React.FC<ListProps> = props => {
  return (
    <List
      {...props}
      pagination={false}
      title={'Document Types'}
      bulkActionButtons={false}
      exporter={false}>
      <DocumentGrid />
    </List>
  );
};

export default DocumentList;
