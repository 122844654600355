import { CustomerModel } from '@hindsight/database';

export function formatRatingAttribute(attribute: number | undefined) {
  return attribute !== null ? `${Math.round(attribute! * 100)}%` : 'No rating';
}

export function formatStatus(status: CustomerModel['status']) {
  switch (status) {
    case 'enabled':
      return 'Active';
    case 'disabled':
      return 'Inactive';
    default:
      return '';
  }
}
