import React from 'react';
import { Filter, TextInput, SelectInput, DateInput } from 'react-admin';

const CustomerFilters: React.FC = props => (
  <Filter {...props}>
    <SelectInput
      label="Type"
      source="customer->type"
      choices={[
        { id: 'business', name: 'business' },
        { id: 'residential', name: 'residential' },
      ]}
    />
    <TextInput label="Customer" source="customer->name" />
    <TextInput label="Street name" source="address->streetName" />
    <TextInput label="Contact" source="customer->phoneNumber" />
    <DateInput label="Registered after at" source="customer->createdAt_ge" />
    <DateInput label="Registered before at" source="customer->createdAt_le" />
    <SelectInput
      label="Status"
      source="customer->status"
      choices={[
        { id: 'enabled', name: 'Active' },
        { id: 'disabled', name: 'Inactive' },
      ]}
    />
  </Filter>
);

export default CustomerFilters;
