import React from 'react';
import { IconButton, Card } from '@material-ui/core';
import { showStyles } from './styles';
import { ReactComponent as RatingIcon } from '../../../assets/icon-rating.svg';

type ContractorsRatingProps = {
  contractorsRating: number | undefined;
};

const ContractorsRating: React.FC<ContractorsRatingProps> = ({
  contractorsRating,
}) => {
  const classes = showStyles();

  return (
    <Card className={classes.cardContainer}>
      <div className={classes.cardHeader}>
        <IconButton disabled>
          <RatingIcon />
        </IconButton>
      </div>
      <div className={classes.cardContent}>
        <div className={classes.totalFont}>{contractorsRating}</div>
        <div className={classes.textFont}>
          Contractors rating 5 times in last 30 days
        </div>
      </div>
    </Card>
  );
};

export default ContractorsRating;
