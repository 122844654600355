import { makeStyles } from '@material-ui/core';

export const showStyles = makeStyles(theme => ({
  typeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: `${theme.spacing(1)}px`,
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
  },
  cardsContainer: {
    marginTop: `${theme.spacing(1)}px`,
  },
}));

export default showStyles;
