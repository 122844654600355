import React from 'react';
import {
  SimpleShowLayout,
  InjectedFieldProps,
  FunctionField,
} from 'react-admin';
import showStyles from './styles';
import RatingField from 'components/RatingField';
import { CustomerModel } from '@hindsight/database';
import { formatRatingAttribute } from './utils';
import { Link } from 'react-router-dom';

type Customer = RequireField<CustomerModel, 'id'>;

const RatingsTab: React.FC<InjectedFieldProps<Customer>> = props => {
  const classes = showStyles();
  return (
    <div className={classes.cardContainer}>
      <SimpleShowLayout {...props} className={classes.cardContent}>
        <RatingField
          className={classes.cardItem}
          source="ratingValueAverage"
          label="Overall rating"
        />
        <FunctionField<Customer>
          label="Was the customer clear with their requirements?"
          render={record =>
            record && formatRatingAttribute(record.ratingClearRequirementsScore)
          }
        />
        <FunctionField<Customer>
          label="Was the customer easy to work with?"
          render={record =>
            record && formatRatingAttribute(record.ratingEasyToWorkWithScore)
          }
        />
        <FunctionField<Customer>
          label="Did the customer pay promptly?"
          render={record =>
            record && formatRatingAttribute(record.ratingPromptlyPaidScore)
          }
        />
        <FunctionField<Customer>
          label="Would you recomend work with whis customer?"
          render={record =>
            record && formatRatingAttribute(record.ratingRecommendsScore)
          }
        />
        <Link
          to={`/rating?displayedFilters={"customer->id"%3Atrue}&filter={"customer->id"%3A"${props.record?.id}"}&order=ASC&page=1&perPage=10&sort=id`}>
          View all ratings
        </Link>
      </SimpleShowLayout>
    </div>
  );
};

export default RatingsTab;
