import { ContractorModel } from '@hindsight/database';
import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  SelectField,
  ListProps,
  DateField,
  FunctionField,
} from 'react-admin';

import ContractorStatusField from 'components/ContractorStatusField';
import ListPagination from 'components/ListPagination';

import ContractorFilters from '../ContractorFilters';

type Contractor = RequireField<ContractorModel, 'id'>;

const resolveContractorPhase = (contractor: Contractor) => {
  // If contractor does not have a business, it's phase 1
  if (!contractor.contractorBusiness) return 'Phase 1';
  // If contractor does not have subscription, it's phase 2
  if (!contractor.contractorBusiness?.subscription) return 'Phase 2';
  // If contractor already have subscription but does not have identification picture, it's phase 3
  else if (!contractor.identificationPictureSent) return 'Phase 3';
  // If contractor already have subscription and identification picture, it's phase 4
  else return 'Phase 4';
};

const resolveContractorType = (contractor: Contractor) => {
  // If the contractor has no subscription, then the type is unknown
  if (!contractor.contractorBusiness?.subscription) return 'N/A';

  // If the contractor has a subscription and the id of the user who registered
  // is the same id of this contractor, then the type is 'Owner'
  if (contractor.id === contractor.contractorBusiness.subscription.userId)
    return 'Owner';

  // Otherwise your type is 'Coworker'
  return 'Coworker';
};

export const ContractorList: React.FC<ListProps> = props => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<ContractorFilters />}
      exporter={false}
      pagination={<ListPagination />}
      perPage={50}>
      <Datagrid stickyHeader rowClick="show">
        <TextField
          source="contractorBusiness.name"
          label="Business"
          textAlign="center"
        />
        <TextField source="user.name" label="Full name" textAlign="center" />
        <TextField
          source="contractorBusiness.address.formattedFull"
          label="Business address"
          sortable={false} // Address fields should not be sortable
          textAlign="center"
        />
        <FunctionField<Contractor>
          label="Type"
          render={record => record && resolveContractorType(record)}
        />
        <SelectField
          label="Plan"
          source="contractorBusiness.subscription.productSku"
          choices={[
            {
              id: 'business_subscription',
              name: 'Old Business',
            },
            {
              id: 'personal_subscription',
              name: 'Old Personal',
            },
            {
              id: 'new_business_plan',
              name: 'Business',
            },
            {
              id: 'new_personal_plan',
              name: 'Personal',
            },
            {
              id: 'free_admin_subscription',
              name: 'Free admin',
            },
          ]}
          sortBy="contractorBusiness:subscription.productSku"
          textAlign="center"
        />
        <DateField
          label="Validity"
          source="contractorBusiness.subscription.expirationDate"
          sortBy="contractorBusiness:subscription.expirationDate"
          locales="en-US"
          textAlign="center"
        />
        <DateField
          label="Registered at"
          source="createdAt"
          sortBy="contractor.createdAt"
          locales="en-US"
          textAlign="center"
        />
        <ContractorStatusField
          source="user.status"
          label="Status"
          textAlign="center"
        />
        <FunctionField<Contractor>
          label="Phase"
          render={record => record && resolveContractorPhase(record)}
        />
      </Datagrid>
    </List>
  );
};
