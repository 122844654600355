import { makeStyles } from '@material-ui/core';

export const showStyles = makeStyles({
  componentHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  componentContainer: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export const cardStyles = makeStyles(theme => ({
  cardContainer: {
    borderRadius: `${theme.spacing(1)}px`,
    width: '45%',
    margin: `${theme.spacing(2.5)}px`,
    maxHeight: '20%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardHeader: {
    display: 'flex',
    fontSize: `${theme.spacing(3)}px`,
    color: `${theme.palette.text.hint}`,
    padding: `${theme.spacing(1)}px`,
  },
  cardContent: {
    marginTop: `${theme.spacing(2)}px`,
  },
}));
