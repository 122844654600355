import React from 'react';
import {
  SimpleShowLayout,
  DateField,
  InjectedFieldProps,
  useUpdate,
  useNotify,
  FunctionField,
  Labeled,
} from 'react-admin';
import { Link } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import showStyles from './styles';
import { CustomerModel } from '@hindsight/database';
import { formatStatus } from './utils';
import StyledTextField from 'components/StyledTextField';

type Customer = RequireField<CustomerModel, 'id'>;

const DetailsTab: React.FC<InjectedFieldProps> = props => {
  const classes = showStyles();
  const notify = useNotify();
  const [update] = useUpdate();

  const isDisabled = props.record?.status === 'disabled' ? true : false;

  const changeStatus = () => {
    const newStatus = isDisabled ? 'enabled' : 'disabled';
    update(
      'customer',
      props.record?.id,
      { status: newStatus },
      {},
      {
        onSuccess: () => {
          notify('Customer updated');
        },
        onFailure: (error: Error) => {
          notify(error.toString(), 'warning');
        },
      },
    );
  };

  return (
    <div className={classes.cardContainer}>
      <SimpleShowLayout {...props} className={classes.cardContent}>
        <Labeled label="CustomerType">
          <StyledTextField className={classes.cardItem} source="type" />
        </Labeled>
        <Labeled
          label={
            props.record?.type === 'residential'
              ? 'Customer full name'
              : 'Business name'
          }>
          <StyledTextField className={classes.cardItem} source="name" />
        </Labeled>
        <Labeled label="Phone n°">
          <StyledTextField className={classes.cardItem} source="phoneNumber" />
        </Labeled>
        <DateField
          className={classes.cardItem}
          locales="en-US"
          textAlign="center"
          source="createdAt"
          label="Registered at"
        />
        <Labeled label="Address">
          <StyledTextField
            className={classes.cardItem}
            source="address.formattedFull"
          />
        </Labeled>
        {props.record?.createdBy && (
          <Labeled label="Owner">
            <Link
              to={{
                pathname: `/contractor/${props.record?.createdById}/show`,
              }}>
              {props.record?.createdBy.user.name}
            </Link>
          </Labeled>
        )}
        <Labeled label="E-mail">
          <StyledTextField className={classes.cardItem} source="email" />
        </Labeled>
        <div className={classes.statusContainer}>
          <Labeled label="Status">
            <FunctionField<Customer>
              label="Status"
              className={classes.cardItem}
              render={record => record && formatStatus(record.status)}
            />
          </Labeled>
          <Switch
            checked={!isDisabled}
            onChange={changeStatus}
            name="customerStatus"
          />
        </div>
      </SimpleShowLayout>
    </div>
  );
};

export default DetailsTab;
