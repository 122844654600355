import * as React from 'react';
import { FieldProps } from 'react-admin';
import { CustomerModel } from '@hindsight/database';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from 'styles/theme';
type Customer = RequireField<CustomerModel, 'id'>;

const useStyles = makeStyles({
  inactive: {
    color: theme.palette.error.main,
  },
});

const CustomerStatusField: React.FC<FieldProps<Customer>> = ({ record }) => {
  const classes = useStyles();

  let status;
  switch (record?.status) {
    case 'enabled':
      status = 'Active';
      break;
    case 'disabled':
      status = 'Inactive';
      break;
    default:
      status = '';
      break;
  }
  return (
    <>
      <span className={record?.status == 'disabled' ? classes.inactive : ''}>
        {status}
      </span>
    </>
  );
};

export default CustomerStatusField;
