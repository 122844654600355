import React, { useEffect, useState } from 'react';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  Button,
  FieldProps,
  useDataProvider,
  useRecordContext,
} from 'react-admin';

const DownloadImageField: React.FC<FieldProps> = props => {
  const record = useRecordContext(props);
  const dataProvider = useDataProvider();
  const [urlImage, setUrlImage] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dataProvider
      .getOne('contractorIdentificationPicture', { id: record.id })
      .then(({ data }) => {
        setUrlImage(data.signedUrl);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handleDownloadClick = () => {
    window.open(urlImage);
  };

  if (loading) return <CircularProgress />;

  return urlImage ? (
    <div>
      <Button onClick={handleDownloadClick} label="Download file">
        <CloudDownloadIcon />
      </Button>
    </div>
  ) : (
    <>No image uploaded yet</>
  );
};

DownloadImageField.defaultProps = {
  source: 'contractor',
};

export default DownloadImageField;
