import React from 'react';
import { Card, Divider } from '@material-ui/core';
import { showStyles } from './styles';
import { MonthlyPlanType } from '@hindsight/database';
import {
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar,
} from 'recharts';
import { useTheme } from '@material-ui/core/styles';

type MonthlyPlanProps = {
  monthlyPlanData: MonthlyPlanType[] | undefined;
};

const MonthlyPlan: React.FC<MonthlyPlanProps> = ({ monthlyPlanData }) => {
  const classes = showStyles();
  const theme = useTheme();

  const sortedMonthlyPlan = monthlyPlanData?.sort(function (a, b) {
    return a.month < b.month ? -1 : a.month > b.month ? 1 : 0;
  });

  return (
    <Card className={classes.cardContainer}>
      <div className={classes.cardHeader}>Business Plans & Personal Plans</div>
      <Divider variant="middle" />
      <div className={classes.cardContent}>
        <BarChart width={600} height={300} data={sortedMonthlyPlan}>
          <XAxis dataKey="month" interval={'preserveStartEnd'} />
          <YAxis />
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={`${theme.palette.text.disabled}`}
          />
          <Tooltip />
          <Legend />
          <Bar
            name="Business"
            dataKey="businessCount"
            stackId="a"
            fill={`${theme.palette.secondary.main}`}
          />
          <Bar
            name="Personal"
            dataKey="personalCount"
            stackId="a"
            fill={`${theme.palette.tertiary.contrastText}`}
          />
        </BarChart>
      </div>
    </Card>
  );
};

export default MonthlyPlan;
