import React from 'react';
import { Card, Divider } from '@material-ui/core';
import { MonthlyAverageRatingType } from '@hindsight/database';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import { cardStyles } from '../styles';
import { useTheme } from '@material-ui/core/styles';

type MonthlyAverageRatingProps = {
  monthlyAverageRating: MonthlyAverageRatingType[] | undefined;
};

const MonthlyAverageRating: React.FC<MonthlyAverageRatingProps> = ({
  monthlyAverageRating,
}) => {
  const cardClasses = cardStyles();
  const theme = useTheme();

  const sortedMonthlyAverageRating = monthlyAverageRating?.sort(function (
    a,
    b,
  ) {
    return a.month < b.month ? -1 : a.month > b.month ? 1 : 0;
  });

  return (
    <Card className={cardClasses.cardContainer}>
      <div className={cardClasses.cardHeader}>Monthly Average Rating</div>
      <Divider variant="middle" />
      <div className={cardClasses.cardContent}>
        <LineChart width={400} height={300} data={sortedMonthlyAverageRating}>
          <XAxis dataKey="month" interval={'preserveStartEnd'} />
          <YAxis
            dataKey="average"
            ticks={[0, 1, 2, 3, 4, 5]}
            interval={'preserveStartEnd'}
          />
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={`${theme.palette.text.disabled}`}
          />
          <Tooltip />
          <Line
            type="monotone"
            name="Average Rating"
            dataKey="average"
            strokeWidth={2}
            stroke={`${theme.palette.secondary.light}`}
            yAxisId={0}
          />
        </LineChart>
      </div>
    </Card>
  );
};

export default MonthlyAverageRating;
