import * as React from 'react';
import { FieldProps } from 'react-admin';
import { CollectionModel } from '@hindsight/database';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from 'styles/theme';
type Collection = RequireField<CollectionModel, 'id'>;

const useStyles = makeStyles({
  notPaid: {
    color: theme.palette.error.main,
  },
  paid: {
    color: theme.palette.tertiary.light,
  },
});

const CollectionStatusField: React.FC<FieldProps<Collection>> = ({
  record,
}) => {
  const classes = useStyles();

  const resolveStatusLabel = (status: CollectionModel['status']) => {
    switch (status) {
      case 'active':
        return 'Active';
      case 'paid':
        return 'Paid';
      case 'notPaid':
        return 'Not paid';
      default:
        return '';
    }
  };

  const resolveStatusClassName = (status: CollectionModel['status']) => {
    switch (status) {
      case 'active':
        return '';
      case 'paid':
        return classes.paid;
      case 'notPaid':
        return classes.notPaid;
      default:
        return '';
    }
  };

  return (
    <>
      <span className={resolveStatusClassName(record?.status)}>
        {resolveStatusLabel(record?.status)}
      </span>
    </>
  );
};

export default CollectionStatusField;
