import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';

import firebase from 'firebase';
import { useNotify } from 'ra-core';

export default function ForgotPasswordDialog() {
  const notify = useNotify();

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');

  const handleSubmit = async () => {
    await firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => notify('We have e-mailed your password reset link!'))
      .catch(error => notify(error.message));
  };

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    const email = event.target.value;
    setEmail(email);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', margin: '8px 0' }}>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setOpen(true)}>
        Forgot Password?
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Send Password Reset?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            A password reset will be sent to the following email:
          </DialogContentText>
          <TextField
            id="outlined-basic"
            label="Email"
            type="email"
            variant="outlined"
            style={{ width: '100%' }}
            onChange={handleOnChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Send Email
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
