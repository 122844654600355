import * as React from 'react';
import {
  Button,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
  BulkActionProps,
} from 'react-admin';

const RejectRatingsButton = ({ selectedIds }: BulkActionProps) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    'rating',
    selectedIds,
    { status: 'disabled' },
    {
      onSuccess: () => {
        refresh();
        notify('Ratings rejected');
        unselectAll('rating');
      },
      onFailure: (error: Error) => {
        notify(error.toString(), 'warning');
      },
    },
  );

  return <Button label="Reject" disabled={loading} onClick={updateMany} />;
};

export default RejectRatingsButton;
