// TODO: Add correct theme to admin

import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4F82E1',
      light: '#06AAE1',
      dark: '#086A8B',
    },
    secondary: {
      main: '#5B2C99',
      light: '#BA67F0',
    },
    tertiary: {
      main: '#64A810',
      light: '#00FF00',
      dark: '#18C796',
      contrastText: '#49B863',
    },
    text: {
      primary: '#212429',
      secondary: '#434343',
      hint: '#707070',
      disabled: '#d3d3d3',
    },
    error: {
      main: '#F92929',
      dark: '#D43333',
    },
  },
  spacing: 8,
});

export { theme };
