import React from 'react';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import { AuthProvider } from 'providers/auth';
import { DataProvider } from 'providers/data';
import { ContractorList } from 'resources/contractor/ContractorList';
import { CustomerList } from 'resources/customer/CustomerList';
import { RatingsList } from 'resources/rating/RatingList';
import { theme } from 'styles/theme';
import CustomersShow from 'resources/customer/CustomersShow';
import ContractorsShow from 'resources/contractor/ContractorsShow';
import StyledLayout from 'components/StyledLayout';
import Configuration from 'components/Configuration';
import CustomLoginPage from 'components/CustomLoginPage';
import DocumentList from 'resources/document/DocumentList';
import { CollectionList } from 'resources/collection/CollectionList';
import CollectionShow from 'resources/collection/CollectionShow';
import DocumentEdit from 'resources/document/DocumentEdit';
import DashboardShow from 'resources/dashboard/DashboardShow';
import CustomerEdit from 'resources/customer/EditCustomer';
import CreateContractor from 'resources/contractor/CreateContractor';
import EditContractor from 'resources/contractor/EditContractor';

const App: React.FC = () => {
  return (
    <Admin
      theme={theme}
      layout={StyledLayout}
      loginPage={CustomLoginPage}
      authProvider={AuthProvider}
      dataProvider={DataProvider}
      dashboard={DashboardShow}
      customRoutes={[
        <Route
          exact
          path="/configuration"
          key="config"
          component={Configuration}
        />,
      ]}>
      <Resource
        name="contractor"
        show={ContractorsShow}
        list={ContractorList}
        create={CreateContractor}
        edit={EditContractor}
      />
      <Resource
        name="customer"
        show={CustomersShow}
        list={CustomerList}
        edit={CustomerEdit}
      />
      <Resource name="collection" show={CollectionShow} list={CollectionList} />
      <Resource name="document" list={DocumentList} edit={DocumentEdit} />
      <Resource name="rating" list={RatingsList} />
    </Admin>
  );
};

export default App;
