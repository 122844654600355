import React, { useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { validationSchema, FormValues, formInitialValue } from './utils';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { TextField, Button } from '@material-ui/core';
import showStyles from './styles';
import firebase from 'firebase/app';
import { useNotify, Notification } from 'react-admin';

const ChangePassword: React.FC = () => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const classes = showStyles();
  const notify = useNotify();

  const handleSubmit = (
    values: FormValues,
    { resetForm, setFieldError, setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      `${user?.email}`,
      values.currentPassword,
    );

    user
      ?.reauthenticateWithCredential(credential)
      .then(() =>
        user
          .updatePassword(values.newPassword)
          .then(() => {
            notify('Password Updated!');
            resetForm();
          })
          .catch(error => {
            console.log(error);
            notify('Something went wrong. Please, login again.');
          }),
      )
      .catch(error => {
        if (error.code == 'auth/wrong-password') {
          setSubmitting(false);
          setFieldError('currentPassword', 'Wrong password');
        } else {
          notify('Something went wrong. Please, login again.');
        }
      });
  };

  const handleShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleShowRetypePassword = () =>
    setShowRetypePassword(!showRetypePassword);

  return (
    <>
      <div className={classes.container}>
        <Formik
          validationSchema={validationSchema}
          initialValues={formInitialValue}
          onSubmit={handleSubmit}
          validateOnChange={false}>
          {formik => (
            <Form noValidate={true}>
              <TextField
                name="currentPassword"
                type={'password'}
                label="Current password"
                margin="normal"
                variant="outlined"
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.currentPassword)}
                helperText={formik.errors.currentPassword}
                fullWidth
                inputProps={{ 'data-testid': 'currentPassword' }}
              />
              <TextField
                name="newPassword"
                type={showNewPassword ? 'text' : 'password'}
                label="New password"
                margin="normal"
                variant="outlined"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.newPassword)}
                helperText={formik.errors.newPassword}
                fullWidth
                inputProps={{ 'data-testid': 'newPassword' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowNewPassword}>
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                name="retypePassword"
                type={showRetypePassword ? 'text' : 'password'}
                label="Confirm new password"
                margin="normal"
                variant="outlined"
                value={formik.values.retypePassword}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.retypePassword)}
                helperText={formik.errors.retypePassword}
                fullWidth
                inputProps={{ 'data-testid': 'retypePassword' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowRetypePassword}>
                        {showRetypePassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div className={classes.footerContainer}>
                <Button
                  disabled={formik.isSubmitting}
                  type="submit"
                  size="large"
                  variant="outlined"
                  color="primary">
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <Notification />
      </div>
    </>
  );
};

export default ChangePassword;
