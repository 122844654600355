import React from 'react';
import { List, Datagrid, TextField, ListProps, DateField } from 'react-admin';

import CustomerStatusField from 'components/CustomerStatusField';
import ListPagination from 'components/ListPagination';

import CustomerFilters from '../CustomerFilters';

export const CustomerList: React.FC<ListProps> = props => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<CustomerFilters />}
      exporter={false}
      pagination={<ListPagination />}
      perPage={50}>
      <Datagrid stickyHeader rowClick="show">
        <TextField source="type" label="Type" textAlign="center" />
        <TextField source="name" label="Customer" textAlign="center" />
        <TextField
          source="address.formattedFull"
          label="Address"
          sortable={false} // Address fields should not be sortable
          textAlign="center"
        />
        <TextField source="phoneNumber" label="Contact" textAlign="center" />
        <DateField
          locales="en-US"
          textAlign="center"
          source="createdAt"
          label="Registered at"
          sortBy="customer.createdAt"
        />
        <CustomerStatusField
          source="status"
          label="Status"
          textAlign="center"
          sortBy="customer.status"
        />
      </Datagrid>
    </List>
  );
};
