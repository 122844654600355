import React from 'react';
import { Filter, TextInput, SelectInput } from 'react-admin';

const CollectionFilters: React.FC = props => (
  <Filter {...props}>
    <TextInput label="Contractor ID" source="contractor->id" />
    <TextInput label="Contractor name" source="contractor:user->name" />
    <TextInput label="Business name" source="businessName" />
    <TextInput label="Customer name" source="customerName" />
    <SelectInput
      label="Status"
      source="collection->status"
      choices={[
        { id: 'active', name: 'Active' },
        { id: 'paid', name: 'Paid' },
        { id: 'notPaid', name: 'Not Paid' },
      ]}
    />
  </Filter>
);

export default CollectionFilters;
