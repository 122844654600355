import { makeStyles } from '@material-ui/core';

export const showStyles = makeStyles({
  cardContainer: {
    margin: '1em',
  },
  cardStyle: {
    width: 300,
    minHeight: 200,
    margin: '0.5em',
    display: 'inline-block',
    verticalAlign: 'top',
    textAlign: 'center',
    borderRadius: '20px',
  },
  editButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
  },
});

export default showStyles;
