import * as React from 'react';
import {
  Button,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
  BulkActionProps,
} from 'react-admin';

const AcceptRatingsButton = ({ selectedIds }: BulkActionProps) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany();

  const handleClick = () => {
    updateMany(
      'rating',
      selectedIds,
      { status: 'enabled' },
      {
        onSuccess: response => {
          console.log(response);
          refresh();
          notify('Ratings accepted');
          unselectAll('rating');
        },
        onFailure: (error: Error) => {
          notify(error.toString(), 'warning');
        },
      },
    );
  };

  return <Button label="Accept" disabled={loading} onClick={handleClick} />;
};

export default AcceptRatingsButton;
