import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ListProps,
  ArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';

import CollectionStatusField from 'components/CollectionStatusField';
import ListPagination from 'components/ListPagination';

import CollectionFilters from '../CollectionFilters';

export const CollectionList: React.FC<ListProps> = props => {
  return (
    <List
      {...props}
      filters={<CollectionFilters />}
      bulkActionButtons={false}
      exporter={false}
      pagination={<ListPagination />}
      perPage={50}
      title="Paperwork">
      <Datagrid stickyHeader style={{ tableLayout: 'fixed' }} rowClick="show">
        <TextField
          source="contractor.user.name"
          label="Contractor name"
          textAlign="center"
          sortBy="contractor:user.name"
        />
        <TextField
          source="businessName"
          label="Business name"
          textAlign="center"
        />
        <TextField
          source="customerName"
          label="Customer name"
          textAlign="center"
        />
        <ArrayField source="serviceTypes">
          <SingleFieldList linkType={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <CollectionStatusField label="Status" textAlign="center" />
      </Datagrid>
    </List>
  );
};
