import React from 'react';
import { Card, Divider, CardContent } from '@material-ui/core';
import { showStyles } from './styles';
import { cardStyles } from '../styles';

type CollectionDocumentsCreatedProps = {
  collectionDocumentsTotal: number | undefined;
};

const CollectionDocumentsCreated: React.FC<CollectionDocumentsCreatedProps> = ({
  collectionDocumentsTotal,
}) => {
  const classes = showStyles();
  const cardClasses = cardStyles();

  return (
    <Card className={cardClasses.cardContainer}>
      <div className={cardClasses.cardHeader}>Collection Documents Created</div>
      <Divider variant="middle" />
      <CardContent className={classes.cardContentHeight}>
        <div className={classes.cardContent}>
          <div>
            <div className={classes.totalFont}>{collectionDocumentsTotal}</div>
            <div className={classes.textFont}>Free & Paid Documents</div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default CollectionDocumentsCreated;
