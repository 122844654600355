import * as React from 'react';
import { useShowController, ShowProps } from 'react-admin';
import { CollectionDocumentModel } from '@hindsight/database';
import { Typography } from '@material-ui/core';

import CollectionDocumentCard from './CollectionDocumentCard';
import showStyles from './styles';

const CollectionDocumentGrid = (props: ShowProps) => {
  const { record } = useShowController(props);
  const classes = showStyles();

  if (record?.documents.length === 0) {
    return (
      <div className={classes.noCard}>
        <Typography variant="h6">
          Collection does not have any registered documents yet.
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.gridContainer}>
      {record?.documents.map((document: CollectionDocumentModel) => (
        <CollectionDocumentCard
          key={document.createdAt}
          collectionId={record.id}
          document={document}
        />
      ))}
    </div>
  );
};

export default CollectionDocumentGrid;
