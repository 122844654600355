import React from 'react';
import { Login, LoginForm } from 'react-admin';
import ForgotPasswordDialog from '../ForgotPasswordDialog';

const CustomLoginForm: React.FC = props => (
  <div>
    <LoginForm {...props} />
    <ForgotPasswordDialog />
  </div>
);

const CustomLoginPage: React.FC = props => (
  <Login {...props}>
    <CustomLoginForm {...props} />
  </Login>
);

export default CustomLoginPage;
