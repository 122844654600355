import React from 'react';
import {
  Filter,
  SelectInput,
  TextInput,
  DateInput,
  NumberInput,
} from 'react-admin';

const ContractorFilters: React.FC = props => (
  <Filter {...props}>
    <NumberInput label="Business Id" source="contractorBusiness->id" />
    <TextInput label="Business Name" source="contractorBusiness->name" />
    <TextInput label="Contractor name" source="user->name" />
    <TextInput
      label="Address Street name"
      source="contractorBusiness:address->streetName"
    />
    <DateInput label="Registered after at" source="contractor->createdAt_ge" />
    <DateInput label="Registered before at" source="contractor->createdAt_le" />
    <SelectInput
      label="Status"
      source="user->status"
      choices={[
        { id: 'enabled', name: 'Active' },
        { id: 'disabled', name: 'Inactive' },
      ]}
    />
    <SelectInput
      label="Plan"
      source="contractorBusiness:subscription->productSku"
      choices={[
        {
          id: 'business_subscription',
          name: 'Old Business',
        },
        {
          id: 'personal_subscription',
          name: 'Old Personal',
        },
        {
          id: 'new_business_plan',
          name: 'Business',
        },
        {
          id: 'new_personal_plan',
          name: 'Personal',
        },
        {
          id: 'free_admin_subscription',
          name: 'Free admin',
        },
      ]}
    />
    <SelectInput
      label="Phase"
      source="phase"
      choices={[
        { id: 1, name: 'Phase 1' },
        { id: 2, name: 'Phase 2' },
        { id: 3, name: 'Phase 3' },
        { id: 4, name: 'Phase 4' },
      ]}
    />
  </Filter>
);

export default ContractorFilters;
