import { format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { Identifier, useDataProvider } from 'ra-core';
import { CollectionDocumentModel } from '@hindsight/database';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography,
} from '@material-ui/core';

import { ApiGetOneParams } from 'providers/data';
import useStyles from './styles';

export interface CollectionDocumentCardProps {
  collectionId: Identifier;
  document: CollectionDocumentModel;
}

const CollectionDocumentCard: React.FC<CollectionDocumentCardProps> = ({
  collectionId,
  document,
}) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [documentUrl, setDocumentUrl] = useState();

  useEffect(() => {
    dataProvider
      .getOne('collectionDocumentFile', {
        id: collectionId,
        documentTypeId: document.documentTypeId,
      } as ApiGetOneParams)
      .then(({ data }) => setDocumentUrl(data.signedUrl))
      .catch(() => null)
      .finally(() => setLoading(false));
  }, []);

  return (
    <Card key={document.documentTypeId} className={classes.cardStyle}>
      <CardHeader title={`${document.type?.name}`} />
      <CardContent>
        <Typography variant="subtitle1">
          Created at{' '}
          {document.createdAt && format(new Date(document.createdAt), 'P')}
        </Typography>
      </CardContent>
      {loading ? (
        <CircularProgress />
      ) : (
        documentUrl && (
          <CardActions>
            <Button
              color="primary"
              onClick={() => window.open(documentUrl)}
              className={classes.editButton}>
              View document
            </Button>
          </CardActions>
        )
      )}
    </Card>
  );
};

export default CollectionDocumentCard;
