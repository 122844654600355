import * as React from 'react';
import Icon from '@material-ui/icons/Stars';
import { makeStyles } from '@material-ui/core/styles';

import { FieldProps } from 'react-admin';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  root: {
    opacity: 0.87,
    whiteSpace: 'nowrap',
    display: 'flex',
    textAlign: 'center',
  },
  small: {
    width: 15,
    height: 15,
  },
});

const StarRatingField: React.FC<FieldProps> = ({ record }) => {
  const classes = useStyles();
  return record ? (
    <div className={classes.container}>
      <span className={classes.root}>
        {Array(record.value)
          .fill(true)
          .map((_, i) => (
            <Icon key={i} className={classes.small} />
          ))}
      </span>
    </div>
  ) : null;
};

StarRatingField.defaultProps = {
  label: 'resources.reviews.fields.rating',
  source: 'rating',
  addLabel: true,
};

export default StarRatingField;
