import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  EditProps,
  useNotify,
  useRedirect,
  useRefresh,
  Toolbar,
  SaveButton,
  Labeled,
  FunctionField,
} from 'react-admin';
import showStyles from './styles';
import { Switch, Typography } from '@material-ui/core';
import { useState } from 'react';
import { ContractorModel } from '@hindsight/database';
import { formatDate } from 'utils';

type Contractor = RequireField<ContractorModel, 'id'>;

const EditContractor: React.FC<EditProps> = props => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const classes = showStyles();

  const [edittingSubscription, setEdittingSubscription] = useState(false);

  const EditContractorToolbar = (props: JSX.IntrinsicAttributes) => (
    <Toolbar {...props}>
      <SaveButton
        transform={data => {
          return {
            licenseNumber: data?.licenseNumber ?? undefined,
            user: {
              name: data?.user?.name,
              phoneNumber: data?.user?.phoneNumber ?? undefined,
            },
            contractorBusiness: {
              name: data?.contractorBusiness?.name ?? undefined,
              additionalInformation:
                data?.contractorBusiness?.additionalInformation ?? undefined,
              employerIdentificationNumber:
                data?.contractorBusiness?.employerIdentificationNumber ??
                undefined,
              website: data?.contractorBusiness?.website ?? undefined,
              fbPage: data?.contractorBusiness?.fbPage ?? undefined,
              googlePage: data?.contractorBusiness?.googlePage ?? undefined,
              yelpPage: data?.contractorBusiness?.yelpPage ?? undefined,
              instagramPage:
                data?.contractorBusiness?.instagramPage ?? undefined,
            },
            ...(edittingSubscription
              ? {
                  subscription: {
                    expirationDate:
                      data?.contractorBusiness?.subscription?.expirationDate ??
                      undefined,
                  },
                }
              : {}),
          };
        }}
      />
    </Toolbar>
  );

  const onSuccess = async () => {
    notify(`Contractor saved`);
    redirect(`/contractor/${props.id}/show`);
    refresh();
  };

  const onFailure = (error: Error) => {
    notify(error.toString(), 'warning');
  };

  return (
    <Edit
      onFailure={onFailure}
      onSuccess={onSuccess}
      mutationMode="pessimistic"
      {...props}>
      <SimpleForm toolbar={<EditContractorToolbar />}>
        <Typography variant="h5">Business Information</Typography>
        <div className={classes.sectionContainer}>
          <div className={classes.fieldsContainer}>
            <TextInput label="Business Name" source="contractorBusiness.name" />
            <TextInput
              label="Additional Information"
              source="contractorBusiness.additionalInformation"
            />
            <TextInput
              label="Employer Identification Number"
              source="contractorBusiness.employerIdentificationNumber"
            />
          </div>
          <div className={classes.fieldsContainer}>
            <TextInput label="Website" source="contractorBusiness.website" />
            <TextInput
              label="Facebook Page"
              source="contractorBusiness.fbPage"
            />
            <TextInput
              label="Google Page"
              source="contractorBusiness.googlePage"
            />
            <TextInput label="Yelp Page" source="contractorBusiness.yelpPage" />
            <TextInput
              label="Instagram Page"
              source="contractorBusiness.instagramPage"
            />
          </div>
        </div>
        <Typography className={classes.sectionTitle} variant="h5">
          Personal Information
        </Typography>
        <div className={classes.sectionContainer}>
          <div className={classes.fieldsContainer}>
            <TextInput label="Full Name" source="user.name" />
          </div>
          <div className={classes.fieldsContainer}>
            <TextInput label="Phone Number" source="user.phoneNumber" />
            <TextInput label="License Number" source="licenseNumber" />
          </div>
        </div>
        <Typography className={classes.sectionTitle} variant="h5">
          Subscription
        </Typography>
        <div className={classes.sectionContainer}>
          <div className={classes.fieldsContainer}>
            <div className={classes.statusContainer}>
              <Labeled label="Enable editting Subscription">
                <Switch
                  checked={edittingSubscription}
                  onChange={() => {
                    if (!edittingSubscription) {
                      notify(
                        `Editting subscription Expiration Date will: ` +
                          `Set subscription status to Active, if date is in the future; ` +
                          `Set subscription status to Expired, if date is in the past; \n` +
                          `and won't auto-renew.`,
                      );
                    }
                    setEdittingSubscription(!edittingSubscription);
                  }}
                  name="editSubscriptionEnabled"
                />
              </Labeled>
            </div>
          </div>
          <div className={classes.fieldsContainer}>
            {edittingSubscription ? (
              <>
                <DateInput
                  label="Expiration Date"
                  source="contractorBusiness.subscription.expirationDate"
                />
              </>
            ) : (
              <>
                <Labeled label="Expiration Date">
                  <FunctionField<Contractor>
                    render={record =>
                      record &&
                      record.contractorBusiness?.subscription?.expirationDate &&
                      formatDate(
                        record.contractorBusiness.subscription.expirationDate,
                      )
                    }
                  />
                </Labeled>
              </>
            )}
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};

export default EditContractor;
