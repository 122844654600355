import { makeStyles } from '@material-ui/core';

export const showStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  cardItem: {
    marginRight: '10px',
  },
  statusContainer: {
    alignItems: 'center',
    display: 'flex',
  },

  sectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: `${theme.spacing(1)}px`,
  },
  sectionTitle: {
    marginTop: `${theme.spacing(1)}px`,
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
  },
}));

export default showStyles;
