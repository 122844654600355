import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Title } from 'react-admin';
import ChangePassword from './ChangePassword';
import DownloadDatabase from './DownloadDatabase';
import ChangeSettings from './ChangeSettings';
const Configuration: React.FC = () => {
  const [currentTab, setCurrentTab] = React.useState('settings');

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setCurrentTab(newValue);
  };

  return (
    <Card>
      <Title title="Configuration" />
      <CardContent>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          aria-label="simple tabs example">
          <Tab value="settings" label="Settings" />
          <Tab value="plans" label="Plans" />
          <Tab value="data" label="Data" />
          <Tab value="password" label="Password" />
        </Tabs>
        {currentTab == 'password' && <ChangePassword />}
        {currentTab == 'data' && <DownloadDatabase />}
        {currentTab == 'settings' && <ChangeSettings />}
      </CardContent>
    </Card>
  );
};

export default Configuration;
