import React from 'react';
import { Filter, TextInput, SelectInput, DateInput } from 'react-admin';

const RatingFilters: React.FC = props => (
  <Filter {...props}>
    <DateInput label="Date after at" source="customerrating->createdAt_ge" />
    <DateInput label="Date before at" source="customerrating->createdAt_le" />
    <TextInput label="Contractor Name" source="contractor:user->name" />
    <TextInput label="Customer Name" source="customer->name" />
    <TextInput label="Customer Id" source="customer->id" />
    <SelectInput
      label="Rating"
      source="value"
      choices={[
        { id: '1', name: '1' },
        { id: '2', name: '2' },
        { id: '3', name: '3' },
        { id: '4', name: '4' },
        { id: '5', name: '5' },
      ]}
    />
    <TextInput label="Comments" source="customerrating->overallDescription" />
    <SelectInput
      label="Status"
      source="customerrating->status"
      choices={[
        { id: 'awaitingReview', name: 'Awaiting Review' },
        { id: 'enabled', name: 'Accepted' },
        { id: 'disabled', name: 'Rejected' },
      ]}
    />
  </Filter>
);

export default RatingFilters;
