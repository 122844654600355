import { makeStyles } from '@material-ui/core';

export const showStyles = makeStyles(theme => ({
  cardContentHeight: {
    height: '100%',
  },
  cardContent: {
    display: 'flex',
    marginTop: `${theme.spacing(2)}px`,
    alignItems: 'center',
    height: '100%',
  },
  totalFont: {
    fontSize: `${theme.spacing(8)}px`,
    color: `${theme.palette.primary.dark}`,
    fontWeight: 'bold',
  },
  textFont: {
    fontSize: `${theme.spacing(2.2)}px`,
    color: `${theme.palette.primary.dark}`,
    fontWeight: 'bold',
  },
}));

export default showStyles;
