import {
  formatToCents,
  formatToDollars,
  formatBeforeCreationWarnMessage,
} from './utils';
import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  EditProps,
  NumberInput,
  useNotify,
  useRedirect,
  useRefresh,
  maxLength,
  number,
  Toolbar,
  SaveButton,
} from 'react-admin';
import { InputAdornment } from '@material-ui/core';
import showStyles from './styles';

const DocumentEdit: React.FC<EditProps> = props => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const classes = showStyles();

  const DocumentEditToolbar = (props: JSX.IntrinsicAttributes) => (
    <Toolbar {...props}>
      <SaveButton
        transform={data => ({
          ...data,
          id: undefined,
          schema: undefined,
          createdAt: undefined,
          updatedAt: undefined,
          isBusiness: undefined,
          isResidential: undefined,
          productSku: undefined,
        })}
      />
    </Toolbar>
  );

  const onSuccess = () => {
    notify(`Document saved`);
    redirect('/document');
    refresh();
  };

  const onFailure = (error: Error) => {
    notify(error.toString(), 'warning');
  };
  return (
    <Edit
      onFailure={onFailure}
      onSuccess={onSuccess}
      mutationMode="pessimistic"
      {...props}>
      <SimpleForm toolbar={<DocumentEditToolbar />}>
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            <TextInput
              label="Title"
              className={classes.headerFields}
              source="name"
              validate={required()}
            />
            <NumberInput
              label="Price"
              className={classes.headerFields}
              format={(valueInCents: number | undefined) =>
                formatToDollars(valueInCents)
              }
              parse={(valueInDollars: number | undefined) =>
                formatToCents(valueInDollars)
              }
              source="priceInCents"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </div>
          <TextInput
            label="Description"
            multiline
            source="description"
            validate={[
              required(),
              maxLength(690, 'Input is too long. (maximum is 690 characters)'),
            ]}
          />
          <TextInput
            label="When to send"
            multiline
            source="whenToSend"
            validate={[
              required(),
              maxLength(690, 'Input is too long. (maximum is 690 characters)'),
            ]}
          />
          <TextInput
            label="Warning message"
            multiline
            source="beforeCreationWarnMessage"
            parse={(inputText: string) =>
              formatBeforeCreationWarnMessage(inputText)
            }
            validate={[
              maxLength(200, 'Input is too long. (maximum is 200 characters)'),
            ]}
          />
          <TextInput
            label="Notification"
            source="waitingDaysToDeliverNotification"
            validate={[number('Must be a number')]}
            helperText={
              'Number of days the alert will be sent after the invoice if not marked as paid'
            }
          />
        </div>
      </SimpleForm>
    </Edit>
  );
};

export default DocumentEdit;
