import * as Yup from 'yup';

export interface FormValues {
  currentPassword: string;
  newPassword: string;
  retypePassword: string;
}

export const formInitialValue = {
  currentPassword: '',
  newPassword: '',
  retypePassword: '',
};

export const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Enter current password'),
  newPassword: Yup.string()
    .required('Enter new password')
    .min(6, 'Your password must be 6 - 16 characters'),
  retypePassword: Yup.string()
    .required('Confirm new password')
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords must be same'),
});
