import { makeStyles } from '@material-ui/core';

export const showStyles = makeStyles(theme => ({
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: `${theme.spacing(2)}px`,
  },
  totalFontBusiness: {
    fontSize: `${theme.spacing(5.4)}px`,
    color: `${theme.palette.primary.light}`,
    fontWeight: 'bold',
  },
  textFontBusiness: {
    fontSize: `${theme.spacing(2)}px`,
    color: `${theme.palette.primary.light}`,
    fontWeight: 'bold',
  },
  totalFontResidential: {
    fontSize: `${theme.spacing(5.4)}px`,
    color: `${theme.palette.tertiary.dark}`,
    fontWeight: 'bold',
  },
  textFontResidential: {
    fontSize: `${theme.spacing(2)}px`,
    color: `${theme.palette.tertiary.dark}`,
    fontWeight: 'bold',
  },
  totalFont: {
    fontSize: `${theme.spacing(5.4)}px`,
    color: `${theme.palette.text.hint}`,
    fontWeight: 'bold',
  },
  textFont: {
    fontSize: `${theme.spacing(2)}px`,
    color: `${theme.palette.text.hint}`,
    fontWeight: 'bold',
  },
}));

export default showStyles;
