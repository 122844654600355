import * as React from 'react';
import {
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  ArrayField,
  SingleFieldList,
  ChipField,
  Labeled,
} from 'react-admin';
import CollectionDocumentGrid from 'components/CollectionDocumentGrid';
import { Typography } from '@material-ui/core';
import showStyles from './styles';

const CollectionShow: React.FC<ShowProps> = props => {
  const classes = showStyles();

  return (
    <Show {...props} component="div">
      <SimpleShowLayout>
        <Typography variant="h5">Collection Information</Typography>
        <div className={classes.typeContainer}>
          <div className={classes.fieldsContainer}>
            <Labeled label="Contractor name">
              <TextField source="contractor.user.name" />
            </Labeled>
          </div>
          <div className={classes.fieldsContainer}>
            <Labeled label="Customer name">
              <TextField source="customerName" />
            </Labeled>
          </div>
        </div>
        <div className={classes.typeContainer}>
          <div className={classes.fieldsContainer}>
            <Labeled label="Business name">
              <TextField source="businessName" />
            </Labeled>
          </div>
          <div className={classes.fieldsContainer}>
            <Labeled label="Customer address">
              <TextField source="customerAddressString" />
            </Labeled>
          </div>
        </div>
        <div className={classes.typeContainer}>
          <Labeled label="Services Types">
            <ArrayField source="serviceTypes">
              <SingleFieldList linkType={false}>
                <ChipField source="name" />
              </SingleFieldList>
            </ArrayField>
          </Labeled>
        </div>
        <div className={classes.cardsContainer}>
          <CollectionDocumentGrid {...props} />
        </div>
      </SimpleShowLayout>
    </Show>
  );
};

export default CollectionShow;
