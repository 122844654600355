import React, { useState } from 'react';
import showStyles from './styles';
import { useDispatch } from 'react-redux';
import { useNotify, fetchStart, fetchEnd } from 'react-admin';
import httpClient from '../../../providers/CustomHttpClient';
import { apiUrl } from 'providers/data';
import { downloadZip } from './utils';

import { Button, CircularProgress } from '@material-ui/core';
import { ReactComponent as DownloadIcon } from '../../../assets/icon-download.svg';

const DownloadDatabase: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const classes = showStyles();
  const notify = useNotify();

  const handleDownloadClick = async () => {
    if (loading) return;
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator

    const uri = `${apiUrl}/dump-db`;
    httpClient(
      uri,
      {
        method: 'GET',
      },
      'arraybuffer',
    )
      .then(({ body }) => {
        const blobData = new Blob([body], {
          type: 'application/octet-stream',
        });
        downloadZip(blobData, `hindsight-database-${Date.now()}`);
      })
      .catch(error => {
        console.info('[Download CSV Database]: ', error);
        notify('Something went wrong. Please, try again.');
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
      });
  };

  return (
    <div className={classes.container}>
      <Button disabled={loading} onClick={handleDownloadClick}>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <div>
            <DownloadIcon className={classes.icon} />
            <div className={classes.textFont}>Download system data</div>
          </div>
        )}
      </Button>
    </div>
  );
};

export default DownloadDatabase;
