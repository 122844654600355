import React, { useState } from 'react';
import {
  DateField,
  SimpleForm,
  TextInput,
  EditProps,
  Toolbar,
  Button,
  useUpdate,
  useNotify,
  EditController,
  EditView,
  Labeled,
  FunctionField,
} from 'react-admin';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import StarRatingField from '../../../components/StarRatingField';
import RatingAnswerField from '../../../components/RatingAnswerField';
import ContentSave from '@material-ui/icons/Save';
import StyledTextField from 'components/StyledTextField';
import { theme } from 'styles/theme';
import { webUrl } from 'providers/data';
import { CustomerRatingModel } from '@hindsight/database';

export const useStyles = makeStyles({
  cardContainer: { paddingLeft: '10px' },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardItem: {
    marginRight: '10px',
  },
  root: {
    paddingTop: 40,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '1em',
  },
  form: {
    width: 400,
  },
  inlineField: {
    display: 'inline-block',
    width: '50%',
  },
  row: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  saveButton: {
    marginLeft: 'auto',
  },
});

type RatingModel = RequireField<CustomerRatingModel, 'id'>;

interface Props extends EditProps {
  onCancel: () => void;
}

const RatingEdit: React.FC<Props> = ({ onCancel, ...props }) => {
  const classes = useStyles();
  const notify = useNotify();
  const [update, { loading }] = useUpdate();
  const params = useParams<{ id: string }>();
  const [newOverallDescription, setNewOverallDescription] = useState<
    string | null
  >(null);

  const RatingToolbar = (
    props: JSX.IntrinsicAttributes & {
      isOverallDescriptionBeingEdited: boolean;
    },
  ) => (
    <Toolbar {...props}>
      <Button
        label="Accept"
        disabled={loading}
        onClick={handleAcceptClick}
        redirect="show"
      />
      <Button
        label="Reject"
        disabled={loading}
        onClick={handleRejectClick}
        redirect="show"
      />
      <div className={classes.saveButton}>
        <Button
          variant="contained"
          label="Save"
          disabled={!props.isOverallDescriptionBeingEdited || loading}
          onClick={handleSaveClick}>
          <ContentSave />
        </Button>
      </div>
    </Toolbar>
  );

  const handleAcceptClick = () => {
    update(
      'rating',
      params.id,
      { status: 'enabled' },
      {},
      {
        onSuccess: () => {
          notify('Rating accepted');
        },
        onFailure: (error: Error) => {
          notify(error.toString(), 'warning');
        },
      },
    );
  };

  const handleRejectClick = () => {
    update(
      'rating',
      params.id,
      { status: 'disabled' },
      {},
      {
        onSuccess: () => {
          notify('Rating rejected');
        },
        onFailure: (error: Error) => {
          notify(error.toString(), 'warning');
        },
      },
    );
  };

  const handleSaveClick = () => {
    update(
      'rating',
      params.id,
      { overallDescription: newOverallDescription },
      {},
      {
        onSuccess: () => {
          notify('Commentary saved.');
        },
        onFailure: (error: Error) => {
          notify(error.toString(), 'warning');
        },
      },
    );
  };

  const handleOverallDescriptionChange: React.ChangeEventHandler<HTMLInputElement> =
    event => {
      setNewOverallDescription(event.target.value);
    };

  const checkIfOverallDescriptionIsBeingEdited = (
    initialOverallDescription: string,
  ) => {
    if (newOverallDescription === null) {
      // Not edited yet
      return false;
    }
    // If different, it's being edited
    return initialOverallDescription != newOverallDescription;
  };

  return (
    <div className={classes.cardContainer}>
      <div className={classes.title}>
        <IconButton onClick={() => onCancel()}>
          <CloseIcon />
        </IconButton>
      </div>
      <EditController mutationMode={'pessimistic'} {...props}>
        {controllerProps => (
          <EditView {...props} {...controllerProps}>
            <SimpleForm
              toolbar={
                <RatingToolbar
                  isOverallDescriptionBeingEdited={checkIfOverallDescriptionIsBeingEdited(
                    controllerProps.record?.overallDescription,
                  )}
                />
              }
              className={classes.cardContent}>
              <Labeled label="Contractor">
                <StyledTextField
                  className={classes.cardItem}
                  source="contractor.user.name"
                  label="Contractor"
                />
              </Labeled>
              <Labeled label="Customer">
                <StyledTextField
                  className={classes.cardItem}
                  source="customer.name"
                  label="Customer"
                />
              </Labeled>
              <DateField
                className={classes.cardItem}
                locales="en-US"
                textAlign="center"
                source="createdAt"
                label="Registered at"
              />
              <StarRatingField
                className={classes.cardItem}
                source="value"
                label="Rating"
              />
              <Labeled label="Type">
                <StyledTextField
                  className={classes.cardItem}
                  source="customer.type"
                  label="Type"
                />
              </Labeled>
              <Labeled label="Service">
                <StyledTextField
                  className={classes.cardItem}
                  source="serviceType.name"
                  label="Service"
                />
              </Labeled>
              <RatingAnswerField
                className={classes.cardItem}
                source="easyToWorkWith"
                label="Easy to work with"
              />
              <RatingAnswerField
                className={classes.cardItem}
                source="clearRequirements"
                label="Clear Demands"
              />
              <RatingAnswerField
                className={classes.cardItem}
                source="promptlyPaid"
                label="Pay pomptly"
              />
              <RatingAnswerField
                className={classes.cardItem}
                source="recommends"
                label="Would recommend"
              />
              <TextInput
                className={classes.cardItem}
                source="overallDescription"
                label="Comment"
                onChange={handleOverallDescriptionChange}
                rowsMax={15}
                multiline
                fullWidth
              />
              <TextInput
                className={classes.cardItem}
                source="customerAnswer"
                label="Customer Answer"
                InputProps={{
                  style: { color: theme.palette.text.hint },
                }}
                InputLabelProps={{
                  style: { color: theme.palette.text.hint },
                }}
                disabled
                multiline
                fullWidth
              />
              <FunctionField<RatingModel>
                label="Reply"
                className={classes.cardItem}
                render={record => (
                  <a
                    target={'_blank'}
                    href={`${webUrl}/customer/reply/${record && record.key}`}
                    rel="noreferrer">
                    Check reply page
                  </a>
                )}
              />
            </SimpleForm>
          </EditView>
        )}
      </EditController>
    </div>
  );
};
export default RatingEdit;
