import * as React from 'react';
import { FieldProps } from 'react-admin';
import { CustomerRatingModel } from '@hindsight/database';

type Rating = RequireField<CustomerRatingModel, 'id'>;

const RatingStatusField: React.FC<FieldProps<Rating>> = ({ record }) => {
  let status;

  switch (record?.status) {
    case 'awaitingReview':
      status = 'Pending';
      break;
    case 'enabled':
      status = 'Accepted';
      break;
    case 'disabled':
      status = 'Rejected';
      break;
    default:
      break;
  }
  return (
    <>
      <span>{status}</span>
    </>
  );
};

export default RatingStatusField;
