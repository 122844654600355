import { makeStyles } from '@material-ui/core';

export const showStyles = makeStyles(theme => ({
  cardContainer: {
    borderRadius: `${theme.spacing(1)}px`,
    width: '35%',
    margin: `${theme.spacing(2.5)}px`,
    maxHeight: '20%',
    display: 'flex',
    flexDirection: 'row',
  },
  cardHeader: {
    backgroundColor: `${theme.palette.error.dark}`,
    width: '30%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: `${theme.spacing(1)}px`,
  },
  totalFont: {
    fontSize: `${theme.spacing(4.9)}px`,
    color: `${theme.palette.error.dark}`,
  },
  textFont: {
    fontSize: `${theme.spacing(2)}px`,
    color: `${theme.palette.text.hint}`,
  },
}));

export default showStyles;
