import * as React from 'react';
import { FieldProps } from 'react-admin';
import { Typography } from '@material-ui/core';

const DocumentTitle: React.FC<FieldProps> = ({ record }) => {
  return (
    <>
      <Typography variant="h5">{record?.name}</Typography>
    </>
  );
};

export default DocumentTitle;
