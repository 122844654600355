import { fetchUtils } from 'ra-core';
import { firebaseProvider } from '../config/firebase';

type ResponseType = 'arraybuffer' | undefined;

// fetchUtils.fetchJson receives this parameter
const customHttpClient = async (
  url: string,
  options: fetchUtils.Options = {},
  responseType?: ResponseType,
) => {
  const user = await firebaseProvider.getAuthUser();
  if (user) {
    const tokenResult = await user.getIdTokenResult();
    if (tokenResult && tokenResult.token) {
      const requestHeaders = (options.headers ||
        new Headers({
          Accept: 'application/json',
        })) as Headers;

      requestHeaders.set('Authorization', `Bearer ${tokenResult.token}`);
      options.headers = requestHeaders;
    }
  }
  return fetchJson(url, options, responseType);
};

export const fetchJson = (
  url: string,
  options: fetchUtils.Options = {},
  responseType: ResponseType,
) => {
  const requestHeaders = fetchUtils.createHeadersFromOptions(options);

  return fetch(url, { ...options, headers: requestHeaders })
    .then(response =>
      (responseType === 'arraybuffer'
        ? response.arrayBuffer()
        : response.text()
      ).then(text => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
      })),
    )
    .then(({ status, statusText, headers, body }) => {
      let json;
      try {
        if (typeof body === 'string') {
          json = JSON.parse(body);
        }
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        return Promise.reject(
          new Error((json && json.displayMessage) || statusText),
        );
      }
      return Promise.resolve({ status, headers, body, json });
    });
};

export default customHttpClient;
