import * as React from 'react';
import { Show, ShowProps, TabbedShowLayout, Tab } from 'react-admin';
import DetailsTab from './ShowTabs/DetailsTab';
import RatingsTab from './ShowTabs/RatingsTab';

const CustomersShow: React.FC<ShowProps> = props => {
  return (
    <Show component="div" {...props}>
      <TabbedShowLayout>
        <Tab label="Details">
          <DetailsTab />
        </Tab>
        <Tab label="Ratings" path="ratings">
          <RatingsTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default CustomersShow;
