import { AuthProvider as RAAuthProvider } from 'react-admin';
import { firebaseProvider } from '../../config/firebase';

const customFirebaseProvider: RAAuthProvider = {
  ...firebaseProvider,
  checkAuth: async () => {
    const user = await firebaseProvider.getAuthUser();

    if (!user)
      return Promise.reject({
        message: 'User not found.',
      });

    const token = await user.getIdTokenResult();
    const claims = token?.claims;

    const isAdmin = claims?.isAdmin ?? false;

    return isAdmin
      ? Promise.resolve()
      : Promise.reject({
          message: "You don't have access to this dashboard.",
        });
  },
  getPermissions: async () => {
    const user = await firebaseProvider.getAuthUser();

    if (!user) return {};

    const token = await user.getIdTokenResult();
    const claims = token?.claims;

    return { isAdmin: claims?.isAdmin ?? false };
  },
};

export const AuthProvider = customFirebaseProvider;
