import * as React from 'react';
import StarIcon from '@material-ui/icons/Star';
import { FieldProps } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { CustomerModel } from '@hindsight/database';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
  },
});

type Customer = RequireField<CustomerModel, 'id'>;

const RatingField: React.FC<FieldProps<Customer>> = ({ record }) => {
  const classes = useStyles();

  return record?.ratingValueAverage !== null ? (
    <span className={classes.root}>
      <StarIcon /> {record?.ratingValueAverage}/5
    </span>
  ) : (
    <>No rating</>
  );
};

RatingField.defaultProps = {
  label: 'resources.reviews.fields.rating',
  source: 'rating',
  addLabel: true,
};

export default RatingField;
