import React from 'react';
import { Card, Divider, CardContent } from '@material-ui/core';
import { showStyles } from './styles';
import { cardStyles } from '../styles';

type CustomersOnDatabaseProps = {
  customerBusiness: number | undefined;
  customerResidential: number | undefined;
  customerTotal: number | undefined;
};

const CustomersOnDatabase: React.FC<CustomersOnDatabaseProps> = ({
  customerBusiness,
  customerResidential,
  customerTotal,
}) => {
  const classes = showStyles();
  const cardClasses = cardStyles();

  return (
    <Card className={cardClasses.cardContainer}>
      <div className={cardClasses.cardHeader}>Customers on database</div>
      <Divider variant="middle" />
      <CardContent>
        <div className={classes.cardContent}>
          <div className={classes.totalFontBusiness}>{customerBusiness}</div>
          <div className={classes.textFontBusiness}>Business</div>
        </div>
        <div className={classes.cardContent}>
          <div className={classes.totalFontResidential}>
            {customerResidential}
          </div>
          <div className={classes.textFontResidential}>Residential</div>
        </div>
        <div className={classes.cardContent}>
          <Divider variant="fullWidth" />
          <div className={classes.totalFont}>{customerTotal}</div>
          <div className={classes.textFont}>Total</div>
        </div>
      </CardContent>
    </Card>
  );
};

export default CustomersOnDatabase;
