import { makeStyles } from '@material-ui/core';

export const showStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  cardItem: {
    marginRight: '10px',
  },
  statusContainer: {
    alignItems: 'center',
    display: 'flex',
  },
}));

export default showStyles;
