import { makeStyles } from '@material-ui/core';

export const showStyles = makeStyles({
  loading: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

export default showStyles;
